/* inject:lessImports */
/* endinject */
/*GENERIC SITE WIDE CLASSES*/
/* This helps the ng-show/ng-hide animations start at the right place. */
/* Since Angular has this but needs to load, this gives us the class early. */
/*.ng-hide {
    display: none!important;
}*/
/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
.forteGridContainer {
  float: left;
  position: relative;
  width: 100%;
}
.forteGridContainer[type="checkbox"] {
  margin: 0;
}
.forteGridContainer input[type="text"]:disabled {
  background: #eee !important;
  color: inherit;
}
.forteGridContainer .verticalOffsetCentered {
  vertical-align: middle;
  background: lightgray;
}
.forteGridContainer .form-control {
  text-align: center;
  height: 29px;
  padding: 4px 12px;
}
.forteGridContainer .form-control:focus {
  border-color: #4d90fe;
  border-width: 2px;
  padding-left: 10px;
  padding-right: 10px;
  height: 29px;
}
.forteGridContainer .form-control-span {
  vertical-align: middle;
}
.forteGridContainer .addForteGridColumnButton {
  white-space: nowrap;
  padding: 1px;
  margin-bottom: 10px;
  transition: width 0.5s;
  width: 22px;
}
.forteGridContainer .addForteGridColumnButton:hover {
  transition: width 0.35s;
  width: 104px;
}
.forteGridContainer .addForteGridColumnButton:hover span {
  transition: opacity 0.45s;
  opacity: 1;
}
.forteGridContainer .addForteGridColumnButton:focus {
  outline: none;
}
.forteGridContainer .addForteGridColumnButton span {
  font-family: "Helvetica Neue";
  color: black;
  padding-left: 5px;
  margin-right: 5px;
  vertical-align: top;
  transition: opacity 0.05s;
  opacity: 0;
  font-size: 18px;
}
.forteGridContainer .scrollContainer {
  width: auto;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  white-space: nowrap;
}
.forteGridContainer .scrollContainer .columnContainer {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  float: left;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn {
  flex-shrink: 0;
  float: left;
  background-color: white;
  border-right: 1px solid #ccc;
  position: relative;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn > div:first-child {
  border-top: 1px solid #ccc;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div {
  border-bottom: 1px solid #ccc;
  height: 30px;
  overflow: hidden;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div > input[type="checkbox"] {
  margin: 0;
  height: 15px;
  box-shadow: none;
  display: inline;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div > input[type="checkbox"]:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 6px;
  outline-color: #4d90fe;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .disabledTextBlock {
  height: 100%;
  font-style: italic;
  text-align: center;
  padding-top: 6px;
  background-color: #eee;
  border-bottom: 0px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .singleValueContainer {
  height: 100%;
  border-bottom: 0px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .singleValueContainer.loadIsOffMember {
  background-color: #ffff00;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .singleValueContainer > input[type="text"] {
  border: none;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  height: 100%;
  width: 100%;
  background-color: transparent;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .singleValueContainer > input[type="text"]:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer {
  height: 100%;
  border-bottom: 0px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer.disabledTextBlock {
  padding-top: 0px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer.loadIsOffMember {
  background-color: #ffff00;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer > input[type="text"] {
  border: none;
  text-align: center;
  width: 82px;
  height: 29px;
  float: left;
  background-color: transparent;
  padding-top: 7px;
  padding-bottom: 7px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer > input[type="text"]:first-child {
  margin-left: 0px;
  margin-right: 4px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer > input[type="text"]:last-child {
  margin-left: 4px;
  margin-right: 0px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer > input[type="text"]:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn div .multiValueContainer > span {
  width: 10px;
  padding-top: 5px;
  float: left;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn:hover .columnDeleteButton {
  opacity: 1;
  cursor: pointer;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn:hover .columnCopyButton {
  opacity: 1;
  cursor: pointer;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn:last-child {
  border-right: 1px solid #ccc;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .gridCheckboxContainer {
  padding-top: 7px;
  text-align: center;
  vertical-align: middle;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnDeleteButton {
  border-bottom: 0px;
  transition: 0.5s linear all;
  color: red;
  position: absolute;
  left: 7px;
  top: 9px;
  font-size: 16px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnCopyButton {
  border-bottom: 0px;
  transition: 0.5s linear all;
  color: green;
  position: absolute;
  right: 5px;
  top: 8px;
  font-size: 16px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnHeaderCell {
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ccc;
  padding-top: 5px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .commentsBox {
  white-space: nowrap;
  transition: 0.25s height;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .commentsBox:hover {
  transition: 0.25s height;
  height: 150px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnHeader {
  font-weight: bold;
  cursor: default;
  position: relative;
  padding: 4px 6px 6px 6px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnHeader.deSelectedSpan {
  background-color: transparent;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnHeader .columnDeleteButton {
  transition: 0.5s linear all;
  color: red;
  position: absolute;
  left: 7px;
  top: 8px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .columnHeader .columnDeleteButton:focus {
  outline: 0;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .disableOverlay {
  height: 100%;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer {
  position: relative;
  background: white;
  height: 40px;
  overflow: hidden;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitch {
  padding: 6px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitchng-animate {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitchng-leave.ng-leave-active {
  top: -50px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitchng-enter {
  top: -50px;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitchng-leave {
  top: 0;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .animateSwitchContainer .animateSwitchng-enter.ng-enter-active {
  top: 0;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox {
  height: 30px;
  position: relative;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox label {
  margin-left: -10px;
  margin-top: 7px;
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -3px;
  border-radius: 4px;
  left: 50%;
  -webkit-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
  background: #fcfff4;
  background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfff4', endColorstr='#b3bead', GradientType=0);
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  content: "";
  position: absolute;
  width: 13px;
  height: 8px;
  background: transparent;
  top: 4px;
  left: 4px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox label:hover::after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  opacity: 0.5;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox input[type="checkbox"]:checked + label:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
}
.forteGridContainer .scrollContainer .columnContainer .gridColumn .customCheckBox input[type="checkbox"]:disabled + label {
  border-radius: 4px;
  cursor: not-allowed;
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
  -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.4);
  background: linear-gradient(to bottom, #222 0%, #45484d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#222', endColorstr='#45484d', GradientType=0);
}
.forteGridContainer .rowHeadersContainer {
  font-weight: bold;
  width: 150px;
  float: left;
}
.forteGridContainer .rowHeadersContainer .rowHeader {
  height: 30px;
  padding: 3px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  font-size: 12px;
}
.forteGridContainer .rowHeadersContainer .rowHeader.deSelected {
  background-color: transparent;
}
.forteGridContainer .rowHeadersContainer .rowHeader:first-child {
  border-top: 1px solid #ccc;
}
.forteGridContainer .bulletedMessagesContainer {
  margin-top: 10px;
  font-size: 14px;
  margin-left: -23px;
}
.iziToast .iziToast-close {
  top: -30%;
}
.iziToast-wrapper {
  padding: 1% 15px 10px 15px;
}
.iziToast.iziToast-layout3 {
  width: 100%;
}
.iziToast.iziToast-layout3 .iziToast-body > strong {
  width: 100%;
}
.iziToast.iziToast-layout3 .iziToast-body > p {
  width: 100%;
}
.iziToast.iziToast-layout3 .iziToast-body .iziToast-title {
  padding-bottom: 1%;
}
.iziToast.iziToast-layout3 .iziToast-body .iziToast-icon {
  top: 25%;
}
.iziToast-overlay {
  z-index: 1031;
  background: rgba(1, 106, 58, 0.5) !important;
}
.btnEditName {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #37c9db;
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #666;
  opacity: 0;
  visibility: hidden;
  transition: visibility 1.5s, opacity 0.2s;
  transition-delay: 0.2s;
  animation: editName-out 0.3s;
  animation-delay: 0.2s;
  color: black;
}
.btnEditName:hover {
  background: #016a3a;
  color: white;
}
.btnEditName .editButtonIcon {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
}
@keyframes editName-in {
  from {
    transform: rotateZ(180deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}
@keyframes editName-out {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(180deg);
  }
}
.floatLeft {
  float: left;
}
.failedText {
  color: #ff0000;
}
.textLeft {
  text-align: left;
  vertical-align: middle !important;
}
.textCenter {
  text-align: center;
  vertical-align: middle !important;
}
.textRight {
  text-align: right;
  vertical-align: middle !important;
}
.checkboxWithLabel {
  line-height: 20px;
  margin-bottom: 5px;
}
.loadsColumnHeader.selected {
  background-color: #71FFFF;
}
.displayNone {
  display: none !important;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
  text-align: center;
}
.right-inner-addon p {
  margin-bottom: 0px;
}
.ng-hide-add,
.ng-hide-remove {
  /* ensure visibility during the transition */
  display: block !important;
  /* yes, important */
}
.index-splash {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #656565;
  margin-top: -75px;
}
.index-splash p {
  font-size: 45px;
  position: absolute;
  bottom: 0;
  left: 20px;
}
body {
  margin: 0;
  background: #000;
  height: 100%;
  max-height: 100%;
  background-color: white;
  padding-top: 1vw;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  overflow-y: scroll;
}
a {
  color: #7a7a7a;
  text-decoration: none;
}
a:hover {
  color: #d51700;
}
/*END GENERIC SITE WIDE CLASSES*/
/*MAIN LAYOUT CLASSES*/
.leftColumn {
  background-color: #f0f0f0;
  padding-left: 15px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 143px;
  /*Width of frame div*/
  overflow: hidden;
  /*Disable scrollbars. Set to "scroll" to enable*/
  margin-top: 110px;
}
.leftColumn-content {
  margin-top: 15px;
}
/*rightColumn*/
.mainContent {
  position: fixed;
  top: 110px;
  left: 158px;
  /*Set left value to WidthOfFrameDiv*/
  right: 0;
  bottom: 0;
  overflow: auto;
}
.header {
  position: fixed;
  background: #f0f0f0;
  background-image: url(/images/nav_header_logo.png);
  background-repeat: no-repeat;
  height: 110px;
  border: 0 solid black;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
}
.content {
  background: #f7f7f6;
  background-image: url(/images/content_bg.png);
  background-repeat: repeat-x;
  height: 2000px;
  padding: 15px;
}
/*END MAIN LAYOUT CLASSES

/*TOP NAVIGATION BAR CLASSES*/
.nav-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow: hidden;
}
.nav-left {
  padding-left: 168px;
  overflow: auto;
  font-size: 20px;
}
.nav-top {
  height: 65px;
}
.nav-text-logo {
  font-family: "Franklin Gothic Heavy";
  font-size: 40px;
  color: #d51700;
  float: left;
}
.nav-top .links {
  float: left;
  padding-top: 6px;
  padding-left: 10px;
}
/*style the button for modal dialog like a regular link*/
.nav-top .links div {
  float: left;
  padding: 0 7px 0 0;
  color: #7a7a7a;
  text-decoration: none;
}
.nav-top .links div:hover {
  cursor: pointer;
  color: #d51700;
}
/*end button style*/
.nav-bottom .links {
  padding-top: 15px;
}
.nav-bottom .links a {
  padding-right: 20px;
}
nav * a {
  user-select: none;
}
/*END TOP NAVIGATION BAR CLASSES*/
#newFileNameInputContainer {
  padding-top: 8.8px;
}
/*.form-control
{
    display: block;
    width: 280px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}*/
/*.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}*/
.btn-default {
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;
}
.btn-default.active,
.btn-default:active,
.btn-default:focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #adadad;
}
.btn-default:hover,
.open .dropdown-toggle.btn-default {
  color: #333333;
  background-color: #ebebeb;
  border-color: #adadad;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
/* Override the default bootstrap behavior where horizontal description lists
   will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
  white-space: normal;
}
#mainContentTabParentContainer {
  padding: 30px;
}
.mainContentTab {
  display: none;
  background-color: AppWorkspace;
}
/*main navigation styling for divs.*/
.tabButton:active,
.tabButton:hover {
  outline: 0;
}
.disabledTab {
  pointer-events: none;
  opacity: 0.6;
}
.nav > li > .tabButton {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #7a7a7a;
  text-decoration: none;
  outline: 0;
  /*gets rid of dotted border on focused a tags*/
}
.nav > li > .tabButton:hover {
  text-decoration: none;
  background-color: #eeeeee;
  cursor: pointer;
  color: #d51700;
}
.nav > li > .tabButton.active {
  color: #5cb85c;
}
/*!
 * Start Bootstrap - SB Admin 2 Bootstrap Admin Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
.float-left,
.floatLeft {
  float: left;
}
.float-right,
.floatRight {
  float: right;
}
html {
  margin: 0;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}
body {
  height: 100%;
  min-height: 100%;
}
#leftColumnHeader {
  height: 42px;
  flex-shrink: 0;
  background: #fff;
}
#leftContent {
  flex: 1;
  background: white;
}
#leftFooter {
  height: 43px;
  flex-shrink: 0;
  background-color: #f8f8f8;
}
#main-wrapper {
  height: 100%;
  overflow-y: auto;
  padding: 75px 0 0;
}
#main {
  position: relative;
  height: 100%;
  overflow-y: auto;
}
#sidebar-wrapper {
  height: 100%;
  padding: 76px 0 0;
  position: fixed;
  border-right: 1px solid gray;
}
#sidebar {
  position: relative;
  height: 100%;
  overflow: hidden;
}
#sidebar .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.waiting-splash {
  position: relative;
}
.waiting-splash p {
  position: absolute;
  right: 25px;
  bottom: 25px;
  font-size: 30px;
}
@media (min-width: 768px) {
  #main-wrapper {
    float: right;
  }
}
@media (max-width: 768px) {
  #main-wrapper {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  #sidebar-wrapper {
    position: static;
    height: auto;
    max-height: 300px;
    border-right: 0;
  }
}
@media print {
  .hide-print {
    display: none;
  }
}
.footer {
  background-color: #f8f8f8;
  bottom: 0;
  position: fixed;
  padding: 10px;
}
#footer .navbar-nav > li > a {
  padding-top: 3px;
  padding-bottom: 5px;
  font-size: 13px;
}
#footer .nav > li > .tabButton {
  padding: 10px 5px;
}
.navbar-top-links .logout-button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.navbar-top-links .logout-button:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.navbar-top-links {
  margin-right: 0;
}
.navbar-top-links li {
  display: inline-block;
}
.navbar-top-links li:last-child {
  margin-right: 15px;
}
.navbar-top-links li a {
  padding: 30px 15px 15px;
  min-height: 75px;
}
.navbar-top-links li a .fa {
  font-size: 20px;
}
.navbar-top-links .dropdown-menu li {
  display: block;
  cursor: pointer;
}
.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}
.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}
.navbar-top-links .dropdown-alerts,
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks {
  width: 310px;
  min-width: 0;
}
.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}
.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}
.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}
.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}
.navbar-top-links .dropdown-file {
  right: 0;
  left: auto;
}
.sidebar .sidebar-nav.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}
.sidebar .sidebar-search {
  padding: 15px;
}
.sidebar ul li {
  border-bottom: 1px solid #e7e7e7;
}
.sidebar ul li a.active {
  background-color: #5cb85c;
  color: white;
}
.sidebar .arrow {
  float: right;
}
.sidebar .fa.arrow:before {
  content: "\f104";
}
.sidebar .active > a > .fa.arrow:before {
  content: "\f107";
}
.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: 0 !important;
}
.sidebar .nav-second-level li a {
  padding-left: 37px;
}
.sidebar .nav-third-level li a {
  padding-left: 52px;
}
@media (min-width: 768px) {
  .sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
    margin-top: 76px;
  }
  .navbar-top-links .dropdown-alerts,
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks {
    margin-left: auto;
  }
}
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
}
.btn-primary.btn-outline {
  color: #428bca;
}
.btn-success.btn-outline {
  color: #5cb85c;
}
.btn-info.btn-outline {
  color: #5bc0de;
}
.btn-warning.btn-outline {
  color: #f0ad4e;
}
.btn-danger.btn-outline {
  color: #d9534f;
}
.btn-danger.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-warning.btn-outline:hover {
  color: #fff;
}
.chat {
  margin: 0;
  padding: 0;
  list-style: none;
}
.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #999;
}
.chat li.left .chat-body {
  margin-left: 60px;
}
.chat li.right .chat-body {
  margin-right: 60px;
}
.chat li .chat-body p {
  margin: 0;
}
.chat .glyphicon,
.panel .slidedown .glyphicon {
  margin-right: 5px;
}
.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll;
}
.login-panel {
  margin-top: 25%;
}
.flot-chart {
  display: block;
  height: 400px;
}
.flot-chart-content {
  width: 100%;
  height: 100%;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_desc_disabled {
  background: 0 0;
}
table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  float: right;
  font-family: fontawesome;
}
table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}
table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, 0.5);
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857143;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}
.show-grid {
  margin: 15px 0;
}
.huge {
  font-size: 40px;
}
.panel-green {
  border-color: #5cb85c;
}
.panel-green .panel-heading {
  border-color: #5cb85c;
  color: #fff;
  background-color: #5cb85c;
}
.panel-green a {
  color: #5cb85c;
}
.panel-green a:hover {
  color: #3d8b3d;
}
.panel-red {
  border-color: #d9534f;
}
.panel-red .panel-heading {
  border-color: #d9534f;
  color: #fff;
  background-color: #d9534f;
}
.panel-red a {
  color: #d9534f;
}
.panel-red a:hover {
  color: #b52b27;
}
.panel-yellow {
  border-color: #f0ad4e;
}
.panel-yellow .panel-heading {
  border-color: #f0ad4e;
  color: #fff;
  background-color: #f0ad4e;
}
.panel-yellow a {
  color: #f0ad4e;
}
.panel-yellow a:hover {
  color: #df8a13;
}
/*styling for navigationTabButtons*/
.tabButton:active,
.tabButton:hover {
  outline: 0;
}
.nav > li > .tabButton {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #7a7a7a;
  text-decoration: none;
}
.nav > li > .tabButton:hover {
  text-decoration: none;
  background-color: #eeeeee;
  cursor: pointer;
  color: #d51700;
}
.nav > li > .tabButton.active {
  color: #5cb85c;
}
.animate {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0.5s;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: both;
  -o-animation-duration: 0.5s;
  -o-animation-timing-function: ease;
  -o-animation-fill-mode: both;
  -ms-animation-duration: 0.5s;
  -ms-animation-timing-function: ease;
  -ms-animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
#wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: 100%;
  min-width: 0px;
}
#leftColumnMain {
  display: block;
  width: 237px;
  border-right: 1px solid #d9d9d9;
  position: relative;
}
#jobTreeContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#rightColumnMain {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  background: white;
  position: relative;
  width: calc(100% - 237px);
  min-width: 0px;
}
#rightContent {
  flex: 1;
  overflow-y: auto;
  display: flex;
  width: 100%;
  min-width: 0px;
  position: relative;
}
#rightContent .contentChild {
  flex: 1;
  /*position:relative;*/
  width: 100%;
  min-width: 0px;
}
#rightContent .contentChild .tabContent {
  height: 100%;
}
#rightContent .contentChild .messagesContainer {
  padding-top: 10px;
  float: left;
}
#rightFooter {
  z-index: 2;
  height: 41px;
  flex-shrink: 0;
  background-color: #e2e0e0;
}
.contentChild .graphicModal {
  top: 20px;
}
.contentChild .graphicBorders {
  border-bottom-color: #e7e6e6;
}
.btnChartZoom {
  padding: 5px 10px;
  margin-left: 10px;
}
.modal-full {
  min-width: 97%;
  margin: 5px;
}
.modal-full .modal-content {
  min-height: 98vh;
}
.modal-headerZoom {
  border-bottom: 1px solid #fff;
}
.graphBodyInModal {
  padding: 0px !important;
}
@media only screen and (orientation: portrait) {
  .rotateGraph {
    transform: rotate(90deg);
    width: 93vh;
    top: 21vh;
    left: -35%;
  }
}
@media only screen and (orientation: landscape) {
  .graphic {
    background: white;
    color: #fff;
    position: relative;
    left: 0px;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #e7e6e6;
    /*-webkit-transition:top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -moz-transition:top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transition: top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);*/
    /*animation: fadeInDown;*/
    overflow: hidden;
    box-sizing: border-box;
  }
  .graphic.ng-hide-add {
    animation: 0.35s fadeOutUpCustom ease-in-out;
  }
  .graphic.ng-hide-add #drawingLabelsContainer,
  .graphic.ng-hide-add #drawingCanvas {
    animation: 0.35s fadeOutUpCustomPosition ease-in-out;
  }
  .graphic.ng-hide-remove {
    animation: 0.35s fadeInDownCustom ease-in-out;
  }
  .graphic.ng-hide-remove #drawingLabelsContainer,
  .graphic.ng-hide-remove #drawingCanvas {
    animation: 0.35s fadeInDownCustomPosition ease-in-out;
  }
  @keyframes fadeOutUpCustomPosition {
    0% {
      top: 0px;
    }
    1% {
      top: 2.82px;
    }
    100% {
      top: -282px;
    }
  }
  @keyframes fadeOutUpCustom {
    0% {
      height: 282px;
    }
    1% {
      height: 279.18px;
    }
    100% {
      height: 0px;
    }
  }
  @keyframes fadeInDownCustomPosition {
    100% {
      top: 0px;
    }
    99% {
      top: -2.82px;
    }
    0% {
      top: -282px;
    }
  }
  @keyframes fadeInDownCustom {
    100% {
      height: 282px;
    }
    99% {
      height: 279.18px;
    }
    0% {
      height: 0px;
    }
  }
}
.graphic {
  background: white;
  color: #fff;
  position: relative;
  left: 0px;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /*-webkit-transition:top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    -moz-transition:top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transition: top 500ms cubic-bezier(0.17, 0.04, 0.03, 0.94);*/
  /*animation: fadeInDown;*/
  overflow: hidden;
  box-sizing: border-box;
}
.graphic.ng-hide-add {
  animation: 0.35s fadeOutUpCustom ease-in-out;
}
.graphic.ng-hide-add #drawingLabelsContainer,
.graphic.ng-hide-add #drawingCanvas {
  animation: 0.35s fadeOutUpCustomPosition ease-in-out;
}
.graphic.ng-hide-remove {
  animation: 0.35s fadeInDownCustom ease-in-out;
}
.graphic.ng-hide-remove #drawingLabelsContainer,
.graphic.ng-hide-remove #drawingCanvas {
  animation: 0.35s fadeInDownCustomPosition ease-in-out;
}
@keyframes fadeOutUpCustomPosition {
  0% {
    top: 0px;
  }
  1% {
    top: 1.82px;
  }
  100% {
    top: -182px;
  }
}
@keyframes fadeOutUpCustom {
  0% {
    height: 182px;
  }
  1% {
    height: 180.18px;
  }
  100% {
    height: 0px;
  }
}
@keyframes fadeInDownCustomPosition {
  100% {
    top: 0px;
  }
  99% {
    top: -1.82px;
  }
  0% {
    top: -182px;
  }
}
@keyframes fadeInDownCustom {
  100% {
    height: 182px;
  }
  99% {
    height: 180.18px;
  }
  0% {
    height: 0px;
  }
}
.graphic-show {
  /*z-index: 22;*/
  top: 0;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.graphic-not-shown-yet {
  top: -300px;
}
.tabVertGraphic {
  height: 270px;
}
.tabVertGraphic img {
  height: 100%;
  display: block;
  margin: 0 auto;
}
#graphicToggle,
#vertGraphicToggle {
  position: absolute;
  appearance: none;
  cursor: pointer;
  left: -100%;
  top: 0;
}
#graphicToggle + label,
#vertGraphicToggle + label {
  top: 42px;
  left: 0;
  z-index: 1000;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  padding: 8px 9px;
  line-height: 8px;
  font-size: 12px;
  text-align: left;
  margin: 0px;
  color: #ffffff;
  font-family: "RalewayRegular", Arial, sans-serif;
  text-decoration: none;
  background: #337ab7;
}
#graphicToggleZoom,
#vertGraphicToggleZoom {
  position: absolute;
  appearance: none;
  cursor: pointer;
  left: -100%;
  top: 0;
}
#graphicToggleZoom + label,
#vertGraphicToggleZoom + label {
  top: 42px;
  left: 0;
  z-index: 1000;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  padding: 8px 9px;
  line-height: 8px;
  font-size: 12px;
  text-align: left;
  margin: 0px;
  color: #ffffff;
  font-family: "RalewayRegular", Arial, sans-serif;
  text-decoration: none;
  background: #5cb85c;
}
.logged-in-splash {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 9999 !important;
}
.logged-in-splash.deleteCreate {
  left: -10px;
  top: -65px;
}
.logged-in-splash.memberDelete {
  top: 0px;
}
.logged-in-splash.splash-bg {
  background-color: #016a3a;
  opacity: 0.5;
}
.logged-in-splash.splash-bg.splash-bg-light {
  opacity: 0.7;
  background-color: #ffffff;
}
.logged-in-splash.splash-foreground {
  display: flex;
  justify-content: center;
  z-index: 10000 !important;
}
.splash-foreground-content {
  align-self: center;
  max-width: 400px;
  max-width: 90%;
}
.splash-foreground-content h3 {
  margin-top: 5px;
  color: #ffffff;
  text-shadow: 2px 2px #000000;
}
.logged-in-splash img {
  width: inherit;
}
.newfileheader {
  margin-top: -10px;
  margin-bottom: 5px;
}
.openfileheader {
  padding-top: -10px;
}
.modal-dialog {
  width: 95% !important;
}
.modalheader {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modalheader .modal-title {
  font-size: 16px;
}
.modalbody {
  position: relative;
  padding: 15px;
  margin-top: -15px;
}
.modalbody h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.modal-footer {
  padding: 15px;
}
#newFileTreeContainer,
#openFileTreeContainer {
  height: 275px;
  border: 1px solid #000000;
  padding: 5px;
  overflow-y: scroll;
}
#newFileInputForm {
  padding-top: 0px;
}
.cssFade {
  transition: 0.5s linear all;
  opacity: 1;
}
.cssFade.ng-hide {
  opacity: 0;
}
.borderlessButton {
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  height: 16px !important;
}
.spinnerOverlay {
  background-color: transparent;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 101;
}
.spinnerOverlay.ng-hide-add {
  animation: 0.35s fadeOut ease;
}
.spinnerOverlay.ng-hide-remove {
  animation: 1s fadeIn ease;
}
.spinnerOverlay .spinner {
  margin: 0px auto;
  width: 54px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute !important;
  right: 5px;
}
.spinnerOverlay .spinner > div {
  background-color: #71FFFF;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  margin: 1px;
}
.spinnerOverlay .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinnerOverlay .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinnerOverlay .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinnerOverlay .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.disableOverlay {
  background-color: grey;
  opacity: 0.65;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.disableOverlay.ng-hide-add {
  animation: 0.35s fadeOutOfOpaque ease;
}
.disableOverlay.ng-hide-remove {
  animation: 1s fadeIntoOpaque ease;
}
@keyframes fadeIntoOpaque {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0.65;
  }
}
@keyframes fadeOutOfOpaque {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.commentsCell {
  text-overflow: ellipsis;
  overflow: hidden;
}
.jstree-default .jstree-anchor {
  cursor: pointer;
  text-decoration: none;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li > a {
  cursor: pointer;
}
.nav-tabs > li.active > a {
  font-weight: bold;
}
input[type="text"]::-ms-clear {
  display: none !important;
}
#connectionOptions {
  height: 35px;
}
#jstree-marker {
  display: none !important;
}
.tour-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.passwordInformation {
  display: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.passwordInformationResetPassword {
  display: none;
  right: 0px;
  bottom: -50px;
}
.passwordInformationResetPasswordShow {
  display: block;
  right: 0px;
  bottom: -50px;
}
.passwordValidation {
  display: block;
  position: absolute;
  z-index: 10000;
  bottom: 4px;
  right: -350px;
  width: 340px;
  padding: 15px;
  font-size: 0.875em;
  border-radius: 5px;
  background: #fefefe;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
}
.passwordValidation ul {
  padding: 0px;
}
.passwordValidation ul,
.passwordValidation li {
  padding: 0px;
  margin: 0;
  list-style-type: none;
}
.passwordValidation1 ul,
.passwordValidation1 li {
  list-style-type: none;
  padding: 0px;
}
.resetPasswordValidation {
  font-size: 0.875em;
}
.resetPasswordValidation ul,
.resetPasswordValidation li {
  margin: 0;
  padding: 2px 0px 2px 0px;
  list-style-type: none;
}
.confirmPasswordValidation {
  font-size: 0.875em;
}
.confirmPasswordValidation ul,
.confirmPasswordValidation li {
  margin: 0;
  padding: 2px 0px 2px 0px;
  list-style-type: none;
}
.caretLeft {
  position: absolute;
  right: 20px;
  bottom: 194px;
  width: 25px;
}
.caretLeftChanged {
  position: absolute;
  right: 20px;
  bottom: 125px;
  width: 25px;
}
.invalid {
  background-image: url(/images/invalid.png);
  background-size: 13px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 6px;
  padding-left: 19px !important;
  line-height: 24px;
  color: #ec3f41;
}
.valid {
  background-image: url(/images/valid.png);
  background-size: 13px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0 6px;
  padding-left: 22px !important;
  line-height: 24px;
  color: #5cb85c;
}
.validationMessage {
  height: 20px;
}
.form-groupChanged {
  margin-bottom: 0px !important;
}
.card {
  overflow: visible !important;
}
.languageSelectorContainer {
  margin: 20px auto;
}
.licenseCheckboxContainer {
  display: -webkit-inline-box;
  display: -ms-flexbox;
}
.licenseCheckboxContainer input[type="checkbox"] {
  display: none;
}
.licenseCheckboxContainer input[type="checkbox"] + label {
  display: -webkit-box;
  font-weight: 500;
  font-size: 13px;
  color: black;
  margin-bottom: 13px;
  width: 30px !important;
}
.licenseCheckboxContainer input[type="checkbox"] + label div:first-child {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 5px;
}
.licenseCheckboxContainer input[type="checkbox"]:checked + label div:first-child {
  background: url(/images/check.png) -16px;
  background-color: #4285f4;
  background-size: cover;
}
.licenseCheckboxContainer .textContainer {
  width: 220px;
  margin-bottom: 10px;
  max-height: 40px;
}
.licenseCheckboxContainer a {
  color: blue;
  text-decoration: underline;
}
.signUpDisabled {
  background-color: grey;
}
.signUpDisabled:hover {
  background-color: grey !important;
}
.modal .fade {
  transition: opacity 0.3s linear;
}
.forTourStep {
  width: 0px;
  height: 0px;
}
.unSelectText {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.signUpException {
  padding-left: 5px;
  margin: 10px 0 0 0;
  font-family: Arial;
  font-size: 12px;
  color: #d30000;
}
.softwareLicensingAgreement {
  text-decoration: none !important;
  color: #333 !important;
  background-color: inherit !important;
}
.saveAsDefaultsButtonDiv {
  display: table;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}
.dropdown-menu {
  font-size: 12px;
}
.headerItem {
  float: left;
  white-space: nowrap;
  display: block !important;
  width: 50%;
  text-align: left;
}
.headerItemFloatRight {
  float: right !important;
  white-space: nowrap;
  display: block !important;
  width: 50%;
  text-align: left;
}
.headerItemFrench {
  float: left !important;
  white-space: nowrap;
  display: block !important;
  width: 50%;
  height: 60px;
  text-align: left;
}
.headerItemFloatRightFrench {
  float: right !important;
  white-space: nowrap;
  display: block !important;
  width: 50%;
  height: 60px;
  text-align: left;
}
.headerItemFrench a {
  white-space: normal;
}
.headerItemFloatRightFrench a {
  white-space: normal;
}
.closeBtn {
  float: right !important;
  bottom: 5px;
  right: 5px;
  z-index: 99;
  font-size: 10px;
  border: none;
  outline: none;
  background-color: #000;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}
.closeBtn:hover {
  background-color: #555;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li {
  float: left !important;
}
.dropdownlistcenter {
  padding-left: 0px !important;
}
.dropdown-menu-Center {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, 0);
  width: 190px;
  text-align: left;
}
.dropdownlistcenter {
  text-align: center;
}
.dropdownlistwidth {
  width: 190px;
  padding-bottom: 1px;
}
.jobsettingbutton {
  border: 1px solid;
}
body {
  padding-top: 0;
}
.material-switch > input[type="checkbox"] {
  display: none;
}
.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}
.material-switch > label::before {
  background: #000000;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}
.materialswitchDisabled > label::after {
  background: #000 !important;
}
.materialswitchDisabled > label::before {
  background: #000 !important;
}
@media screen and (max-width: 350px) {
  .jobsettingbutton {
    padding-left: 0px !important;
  }
  .rowHeadersContainer,
  .gridsContainer {
    font-size: 11px !important;
  }
  .gridColumn select {
    font-size: 11px !important;
  }
  .singleValueContainer .form-control {
    font-size: 12px !important;
  }
}
.pathContainer {
  height: 26px;
  flex-shrink: 0;
  display: flex;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 1001;
  padding-bottom: 35px;
  padding-right: 5px;
  border-bottom: solid 1px #ddd;
  left: 0px;
}
.pathContainer nav {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 6px;
  padding-top: 7px;
}
.pathContainer ul {
  list-style-type: none;
  margin: 0;
  padding-left: 13px;
  padding-top: 10px;
  display: inline-flex;
}
.pathContainer ul li a {
  background: #ddd;
  padding: 9px 2px;
  float: left;
  color: #444;
  position: relative;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  margin-right: 20px;
}
.pathContainer ul li a:hover {
  background: #888;
}
.pathContainer ul li a:hover:before {
  border-color: #888 #888 #888 transparent;
}
.pathContainer ul li a:hover:after {
  border-left-color: #888;
}
.pathContainer ul li a:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -17px;
  border-top: 17px solid #ddd;
  border-bottom: 16px solid #ddd;
  border-left: 16px solid transparent;
  left: -15px;
}
.pathContainer ul li a:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -18px;
  border-top: 17px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 16px solid #ddd;
  right: -15px;
}
.pathContainer ul span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pathContainer ul li:not(:last-child) a {
  max-width: 160px;
}
.caret {
  margin-left: 8px !important;
}
select:disabled {
  background: #ccc;
  border: #ccc;
  color: #ccc;
  -webkit-text-fill-color: #888;
  opacity: 0.5;
  -webkit-opacity: 0.5;
}
.jobbody {
  margin-top: 45px;
}
.topnavfilemanegment {
  top: 53px;
}
.baricontopnav {
  margin-left: 10px;
}
.topnavMemebermanegment {
  top: 53px;
}
.wyLegalFooter {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: -10px;
  border-top: 10px solid #4d4d4d;
}
.wyLegalFooter .wyInfo {
  vertical-align: bottom;
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #cacccc;
  background-color: #323232;
  width: 100%;
}
.wyLegalFooter .wyInfo .wyLinks {
  clear: left;
  display: flex;
  justify-content: center;
}
.wyLegalFooter .wyInfo .wyLinks a {
  color: #cacccc;
  background: transparent;
}
.wyLegalFooter .wyInfo .wyLinks a a:hover,
.wyLegalFooter .wyInfo .wyLinks a a:focus {
  text-decoration: underline;
}
.wyLegalFooter .wyInfo .wyLinks a a:active,
.wyLegalFooter .wyInfo .wyLinks a a:hover {
  outline: 0;
}
.wyLegalFooter .wyInfo .wyLinks .linkAnchor {
  float: left;
}
.wyLegalFooter .wyInfo .wyLinks .linkDivider {
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}
.wyLegalFooter .wyInfo .wyCopyright {
  clear: left;
  text-align: center;
}
