.shellWrapper {
  height: 100%;
  min-height: 100%;
  min-width: 0px;
  margin-top: 55px;
}
.navoverlay {
  z-index: 9998;
  /* Sit on top */
}
/*Mobile Side Menu Overlay*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.navbar-toggle.navbar-left {
  float: left;
  margin-left: 10px;
}
.navoverlay-content.navbar-left {
  float: left;
  margin-left: 10px;
}
/*end*/
/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media (max-width: 345px) {
  .navoverlay {
    width: 100%;
  }
}
@media (max-width: 380px) and (min-width: 346px) {
  .navoverlay {
    width: 100%;
  }
}
@media (max-width: 415px) and (min-width: 380px) {
  .navoverlay {
    width: 100%;
  }
}
@media (max-width: 566px) and (min-width: 416px) {
  .navoverlay {
    width: 90%;
  }
}
@media (max-width: 767px) and (min-width: 567px) {
  .navoverlay {
    width: 60%;
  }
}
@media (max-width: 779px) and (min-width: 666px) {
  .navoverlay {
    width: 50%;
  }
}
@media (max-width: 1200px) and (min-width: 780px) {
  .navoverlay {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .navbar-toggle {
    border-radius: 0px !important;
    padding: 10px 5px;
    margin-bottom: 0px;
    margin-right: 12px;
  }
  .shellWrapper {
    padding-top: 0px;
  }
  /* The Overlay (background) */
  .navoverlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    position: absolute;
    /* Stay in place */
    float: right;
    z-index: 9999;
    /* Sit on top */
    right: 0px;
    background-color: #000000;
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  .navoverlaysidebar {
    position: absolute;
    /* Stay in place */
    float: right;
    z-index: 9999;
    /* Sit on top */
    width: 45%;
    background-color: #000000;
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
  }
  /* Position the content inside the overlay */
  .navoverlay-content {
    position: relative;
    top: 25%;
    /* 25% from the top */
    text-align: center;
    /* Centered text/links */
    margin-top: 10px;
    /* 30px top margin to avoid conflict with the close button on smaller screens */
  }
  /* The navigation links inside the overlay */
  .navoverlay a {
    padding: 8px;
    text-decoration: none;
    display: block;
    /* Display block instead of inline */
    transition: 0.3s;
    /* Transition effects on hover (color) */
  }
  /* When you mouse over the navigation links, change their color */
  .navoverlay a:hover,
  .navoverlay a:focus {
    color: #f1f1f1;
  }
  /* Position the close button (top right corner) */
  .navoverlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
  }
  .languageSelector {
    margin-top: 0.8vw;
  }
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .sidebarli {
    color: #d3d3d3;
  }
  .navbar-default .navbar-nav > li > a {
    color: #d3d3d3;
  }
  .navbarheader {
    margin-bottom: 0px !important;
    background-color: #000;
  }
  .reverseColorNavBtn {
    background-color: #000 !important;
    border-color: #000 !important;
  }
  .sidebarmyfiles {
    color: #f9f9f9;
  }
  .btnManageTeam {
    float: left;
    margin-right: 1vw;
  }
  .fileManagerNavContent {
    padding-top: 2vw;
  }
  .btnsidebarmyfiles {
    background-color: black;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    border-color: #000 !important;
  }
  .btn {
    font-size: 13px;
  }
  .navbar {
    min-height: 50px;
    border: 0px !important;
  }
  .navbar-collapse {
    border-top: 0px !important;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: #000 !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #f8f8f8;
  }
  .fileManagerNavContent li a {
    color: #f8f8f8 !important;
  }
  .navmyfiles {
    min-height: 5vw !important;
    margin-bottom: 1vw !important;
  }
  .modal-dialog {
    width: 95%;
  }
  .btnDefault {
    width: 100%;
    font-size: 3.3vw !important;
  }
  .navbar-brand {
    float: left;
    height: 55px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    margin-left: 3px;
  }
  .headerIcon {
    font-size: 16px;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .fileManagerNavContent {
    padding-top: 2vw;
    overflow: auto;
    height: 100px;
  }
  .fileManagerAddTeam {
    padding-bottom: 2vw;
  }
  .btnDefault {
    width: 100% !important;
    font-size: 13px !important;
  }
  .navoverlay-content {
    overflow: auto;
    height: 195px;
    overflow-x: hidden;
  }
  #jobTreeContainer {
    padding-top: 25px !important;
    top: 25px !important;
  }
  .jobsidenav {
    max-width: 300px;
  }
}
.jobcontainer {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.accordion-toggle:after {
  font-family: 'FontAwesome';
  content: "\f077";
  float: right;
}
.accordion-opened .accordion-toggle:after {
  font-family: 'FontAwesome';
  content: "\f077";
  float: right;
}
.accordion-toggle.collapsed:after {
  font-family: 'FontAwesome';
  content: "\f078";
  float: right;
}
.accordion-toggle {
  color: #000 !important;
  font-size: 16px;
}
.panel-group {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}
.panel-heading-bar {
  background-color: #f5f5f5 !important;
  color: #000 !important;
  padding: 2vw;
}
.panel-heading-bar a:hover {
  color: #000 !important;
}
.jobsidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: rgba(66, 66, 66, 0.95);
  overflow-y: hidden;
}
.jobsidenav-header {
  position: absolute;
  top: 0;
  background-color: #000;
  width: 100%;
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}
.jobsidenav-title {
  font-size: 20px;
  color: white;
  white-space: nowrap;
}
.jobsidenavopenbutton-title {
  font-size: 25px;
  white-space: nowrap;
  cursor: pointer;
  padding-top: 1px;
}
.jobsidenav .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 36px;
  color: #f1f1f1;
  padding-top: 3px;
}
@media screen and (max-height: 450px) {
  .jobsidenav a {
    font-size: 18px;
  }
}
@media screen and (max-width: 330px) {
  .nav-tabs > li > a {
    font-size: 11px !important;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .navbar-toggle {
    margin-top: 17px;
    margin-right: 24px;
    padding: 0px;
  }
  .shellWrapper {
    padding-top: 0px;
  }
  /* The Overlay (background) */
  .navoverlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    position: absolute;
    /* Stay in place */
    float: right;
    z-index: 9999;
    /* Sit on top */
    right: 0px;
    background-color: #000000;
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  .navoverlaysidebar {
    position: absolute;
    /* Stay in place */
    float: right;
    z-index: 9999;
    /* Sit on top */
    background-color: #000000;
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
  }
  /* Position the content inside the overlay */
  .navoverlay-content {
    position: relative;
    top: 25%;
    /* 25% from the top */
    text-align: center;
    /* Centered text/links */
    margin-top: 10px;
    /* 30px top margin to avoid conflict with the close button on smaller screens */
  }
  /* The navigation links inside the overlay */
  .navoverlay a {
    padding: 8px;
    text-decoration: none;
    display: block;
    /* Display block instead of inline */
    transition: 0.3s;
    /* Transition effects on hover (color) */
  }
  /* When you mouse over the navigation links, change their color */
  .navoverlay a:hover,
  .navoverlay a:focus {
    color: #f1f1f1;
  }
  /* Position the close button (top right corner) */
  .navoverlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
  }
  .languageSelector {
    margin-top: 0.8vw;
  }
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .sidebarli {
    color: #d3d3d3;
  }
  .navbar-default .navbar-nav > li > a {
    color: #d3d3d3;
  }
  .navbarheader {
    margin-bottom: 0px !important;
    background-color: #000;
  }
  .reverseColorNavBtn {
    background-color: #000 !important;
    border-color: #000 !important;
  }
  .sidebarmyfiles {
    color: #f9f9f9;
  }
  .btnManageTeam {
    float: left;
    margin-right: 1vw;
  }
  .fileManagerAddTeam {
    padding-bottom: 1.5vw;
  }
  .fileManagerNavContent {
    padding-top: 2vw;
    overflow: auto;
    height: 250px;
  }
  .btnsidebarmyfiles {
    background-color: black;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    border-color: #000 !important;
  }
  .btn {
    font-size: 13px;
  }
  .navbar {
    min-height: 50px;
    border: 0px !important;
  }
  .navbar-collapse {
    border-top: 0px !important;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: #000 !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #f8f8f8;
  }
  .fileManagerNavContent li a {
    color: #f8f8f8 !important;
  }
  .navmyfiles {
    min-height: 5vw !important;
    margin-bottom: 1vw !important;
  }
  .modal-dialog {
    width: 95%;
  }
  .btnDefault {
    width: 100%;
    font-size: 2.3vw !important;
  }
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-nav {
    float: none !important;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-left {
    float: none !important;
  }
  .languageSelectorContainer {
    margin: 20px auto;
  }
  .headerItem {
    float: left !important;
    white-space: nowrap;
    display: block !important;
    width: 50%;
    text-align: left;
  }
  .navbar > .container-fluid .navbar-brand {
    margin-left: 15px;
  }
  .navbar-brand {
    float: left;
    height: 55px;
    padding: 14px 9px;
    font-size: 18px;
    line-height: 20px;
  }
  .headerIcon {
    font-size: 20px;
  }
}
.arrows {
  margin-top: 9px;
  padding-left: 0px;
  white-space: nowrap;
  word-wrap: break-word;
  font-size: 16px;
  overflow-x: scroll;
  height: 30px;
}
.arrows li {
  display: inline-block;
  position: relative;
}
.arrows li::before {
  content: '/';
  margin-right: 5px;
  margin-left: 5px;
}
.arrows li:first-child::before {
  content: '';
  margin-left: 0px;
}
.arrows li a {
  color: #000;
}
.removeMarginBottom {
  margin-bottom: 0px;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #d3d3d3 !important;
}
