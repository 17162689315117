/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.fileManagement {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin-top: 30px;
}
.MMoving {
  opacity: 1;
  width: 300px;
  height: 60px;
  z-index: 1;
  display: absolute;
  background-color: #69a323;
}
.moving {
  position: absolute;
  z-index: 10000;
  background: #4285f4;
  opacity: 1;
  transition-duration: 0.25s;
  -webkit-transition-property: top, left, height, width;
  transition-property: top, left, height, width;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.15, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.15, 1);
  padding: 3px 8px;
}
.moving .fmNameColumn {
  color: white;
}
.moving .fmNameColumn .iconContainer .icon {
  background: #ffffff;
}
.newItemDialog {
  width: 300px;
  height: 100px;
  border-radius: 10px;
  background-color: white;
  box-shadow: -30px 0 30px -30px rgba(0, 0, 0, 0.9);
}
#searchFileManagement {
  width: 55px;
  height: 55px;
  bottom: 78px;
  right: 36px;
  cursor: pointer;
  position: absolute;
}
#searchFileManagement .fab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #E44C36;
  color: white;
  display: block;
  position: absolute;
  right: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 21px;
  box-shadow: 0px 2px 5px #666;
  text-shadow: 1px 1px #720000;
}
#searchFileManagement .backButtonIcon {
  line-height: 40px;
}
#searchFileManagement .fabFlyoutText {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-shadow: 1px 1px #000000;
  border-radius: 3px;
  display: inline;
  position: absolute;
  right: 0px;
  padding: 2px 10px;
  top: 30px;
  opacity: 0;
  box-shadow: 0px 2px 5px #666;
  transition: all 0.3s;
}
#searchFileManagement:hover .fabFlyoutText {
  top: 60px;
  opacity: 1;
}
#btnBackFileManagement {
  width: 55px;
  height: 55px;
  bottom: 23px;
  right: 36px;
  cursor: pointer;
  position: absolute;
}
#btnBackFileManagement .fab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #E44C36;
  color: white;
  display: block;
  position: absolute;
  right: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 21px;
  box-shadow: 0px 2px 5px #666;
  text-shadow: 1px 1px #720000;
}
#btnBackFileManagement .backButtonIcon {
  line-height: 40px;
}
#btnBackFileManagement .fabFlyoutText {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-shadow: 1px 1px #000000;
  border-radius: 3px;
  display: inline;
  position: absolute;
  right: 20px;
  padding: 2px 10px;
  top: 15px;
  opacity: 0;
  box-shadow: 0px 2px 5px #666;
  transition: all 0.3s;
}
#btnBackFileManagement:hover .fabFlyoutText {
  right: 60px;
  opacity: 1;
}
@keyframes fade-in {
  from {
    opacity: 0;
    right: 20px;
  }
  to {
    opacity: 1;
    right: 70px;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    right: 70px;
  }
  to {
    opacity: 0;
    right: 20px;
  }
}
@keyframes close-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-180deg);
  }
}
@keyframes close-out {
  from {
    opacity: 0;
    transform: rotateZ(-180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes back-in {
  from {
    opacity: 0;
    transform: rotateZ(70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes back-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(70deg);
  }
}
.renameItemForm .renameItemText {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right-width: 0;
  height: 40px;
  padding-left: 10px;
  float: left;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  background: #fff;
  border-radius: 3px 0 0 3px;
}
.renameItemForm .renameItemText::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: oblique;
  color: #dbdbdb;
}
.renameItemForm .renameItemText::-moz-placeholder {
  /* Firefox 19+ */
  font-style: oblique;
  color: #dbdbdb;
}
.renameItemForm .renameItemText:-ms-input-placeholder {
  /* IE 10+ */
  font-style: oblique;
  color: #dbdbdb;
}
.renameItemForm .renameItemText:-moz-placeholder {
  /* Firefox 18- */
  font-style: oblique;
  color: #dbdbdb;
}
.renameItemForm .renameItemText:focus {
  outline: 0;
}
.renameItemForm .login-validation {
  font: bold 15px 'Tahoma';
  margin: 0;
}
.renameItemForm .renameItemButton {
  font-size: 14px;
  vertical-align: top;
  overflow: visible;
  position: relative;
  float: right;
  border: 1px solid black;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
}
.renameItemForm .renameItemButton:hover {
  background: #3db8ff;
}
.renameItemForm .renameItemButton:active,
.renameItemForm .renameItemButton:focus {
  background: #0081d1;
  outline: 0;
}
.newItemForm {
  width: 250px;
}
.newItemForm input {
  width: 181px;
  height: 40px;
  padding-left: 10px;
  float: left;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  border: 0;
  background: #fff;
  border-radius: 3px 0 0 3px;
}
.newItemForm input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemForm input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemForm input:-ms-input-placeholder {
  /* IE 10+ */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemForm input:-moz-placeholder {
  /* Firefox 18- */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemForm input:focus {
  outline: 0;
}
.newItemForm .login-validation {
  font: bold 15px 'Tahoma';
  margin: 0;
  position: absolute;
}
.newItemForm button {
  font-size: 14px;
  vertical-align: top;
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
}
.newItemForm button:hover {
  background: #3db8ff;
}
.newItemForm button:active,
.newItemForm button:focus {
  background: #0081d1;
  outline: 0;
}
.fmNameColumn .iconContainer {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  float: left;
  margin-right: 3px;
}
.fmNameColumn .nameContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 34px;
}
.fmNameColumn .nameContainer .newItemForm {
  border-radius: 10px;
}
.fmNameColumn .nameContainer .newItemForm input {
  height: 40px;
  padding-left: 10px;
  float: left;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  border: 0;
  background: #fff;
  border-radius: 3px 0 0 3px;
}
.fmNameColumn .nameContainer .newItemForm input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: oblique;
  color: #dbdbdb;
}
.fmNameColumn .nameContainer .newItemForm input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: oblique;
  color: #dbdbdb;
}
.fmNameColumn .nameContainer .newItemForm input:-ms-input-placeholder {
  /* IE 10+ */
  font-style: oblique;
  color: #dbdbdb;
}
.fmNameColumn .nameContainer .newItemForm input:-moz-placeholder {
  /* Firefox 18- */
  font-style: oblique;
  color: #dbdbdb;
}
.fmNameColumn .nameContainer .newItemForm input:focus {
  outline: 0;
}
.fmNameColumn .nameContainer .newItemForm .login-validation {
  font: bold 15px 'Tahoma';
  margin: 0;
}
.fmNameColumn .nameContainer .newItemForm button {
  font-size: 14px;
  vertical-align: top;
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
}
.fmNameColumn .nameContainer .newItemForm button:hover {
  background: #3db8ff;
}
.fmNameColumn .nameContainer .newItemForm button:active,
.fmNameColumn .nameContainer .newItemForm button:focus {
  background: #0081d1;
  outline: 0;
}
.fmNameColumn .icon {
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 21px;
  background: transparent;
  border-radius: 50%;
  background: #ffffff;
}
.fmNameColumn .icon:before {
  position: absolute;
  padding-top: 7px;
  padding-left: 10px;
  color: black;
  display: block;
  border-radius: 50%;
  height: 38px;
  width: 38px;
}
.moving_done {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
}
.movingDiv {
  height: 45px;
  width: 150px;
  position: absolute;
  z-index: 10000;
  background: #4285f4;
  opacity: 1;
  color: white;
  padding: 3px 8px;
}
a:hover {
  cursor: pointer;
}
.newFolderNameButton {
  right: 29px;
}
.fileManagmentLeftSidebar .contentColumn {
  height: 100%;
}
.fileManagmentLeftSidebar .contentColumn .actionBar {
  height: 48px;
  flex-shrink: 0;
  display: flex;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .container {
  width: 100%;
  padding-left: 0px;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar,
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-inverse {
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  min-height: 50px;
  width: 100%;
  background-color: #d3d3d3 !important;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .mainContainer {
  width: 100%;
  background: pink;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .nav li {
  display: inline;
  color: white;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-inverse .navbar-nav > li > a {
  color: black;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown .cf {
  zoom: 1;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown .cf:after {
  clear: both;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown .cf:before,
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown .cf:after {
  content: "";
  display: table;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown.open .dropdown-menu {
  padding: 10px 15px;
  color: black;
  background-color: #eee;
  border-width: 0px;
  box-shadow: 3px 5px 0px rgba(0, 0, 0, 0.4);
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav li.dropdown.open a {
  background-color: #eee;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .dropdownNewFolder {
  width: 223px;
}
.fileManagmentLeftSidebar .contentColumn .actionBar .navbar-inverse .navbar-nav li a:hover {
  background-color: #c9c9c9;
  color: black;
  height: 100%;
}
@media (max-width: 1000px) {
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-header {
    float: none;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-left,
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-right {
    float: none !important;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-toggle {
    display: block;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-collapse.collapse {
    display: none !important;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav > li {
    float: none;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .fileManagmentLeftSidebar .contentColumn .actionBar .collapse.in {
    display: block !important;
  }
}
.fileManagmentLeftSidebar .contentColumn .pathContainer nav {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 6px;
  padding-top: 7px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer .arrows > li > a .fileName {
  margin-right: 5px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer .membersidenavopenbutton-title {
  margin-right: 0px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer .arrows li:first-child > span {
  padding-left: 5px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul {
  list-style-type: none;
  margin: 0;
  padding-left: 13px;
  padding-top: 10px;
  display: inline-flex;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a {
  background: #ddd;
  padding: 9px 9px;
  float: left;
  color: #444;
  position: relative;
  font-size: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  margin-right: 20px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a:hover {
  background: #888;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a:hover:before {
  border-color: #888 #888 #888 transparent;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a:hover:after {
  border-left-color: #888;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -16px;
  border-top: 16px solid #ddd;
  border-bottom: 16px solid #ddd;
  border-left: 16px solid transparent;
  left: -15px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li a:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -16px;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #ddd;
  right: -15px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 75px;
}
.fileManagmentLeftSidebar .contentColumn .pathContainer ul li:not(:last-child) a {
  max-width: 160px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .recentHeader {
  font-weight: bold;
  user-select: none;
  height: 40px;
  padding-top: 0px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .headerHover {
  padding-top: 10px;
  height: 100%;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .headerHover:hover {
  background-color: #e3e3e3;
  cursor: pointer;
  padding-top: 10px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .sortDescending:after {
  font-family: FontAwesome;
  content: "\f0dd";
  padding-left: 2px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .sortAscending:after {
  font-family: FontAwesome;
  content: "\f0de";
  padding-left: 2px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .dataGridBody {
  overflow-y: scroll;
  display: block;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .separator {
  border-top: 1px solid #ddd;
  border-top-width: 1px;
  border-top-style: solid;
  padding-top: 10px;
  padding-bottom: 10px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameAndModifiedByContainer {
  padding: 0;
  margin: 0px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .iconPadding {
  padding: 0px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .starIcon {
  color: #daa520;
  padding-top: 7px;
  padding-right: 0px;
  padding-left: 0px;
  width: 16px;
}
@media (min-width: 480px) {
  .fileManagmentLeftSidebar .contentColumn .fileContentContainer .iconPadding {
    width: 30px;
  }
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .nameAndEditButton {
  position: relative;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .nameAndEditButton:hover .btnEditName {
  animation: editName-in 0.3s;
  animation-delay: 0.85s;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
  transition-delay: 0.85s;
  visibility: visible;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .nameAndEditButton .btnEditName {
  top: 6px;
  right: 4px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .selected {
  background-color: #4285f4;
  color: #ffffff;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .selected .dragged {
  opacity: 0.1;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer {
  word-wrap: break-word;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm {
  border-radius: 10px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input {
  height: 40px;
  padding-left: 10px;
  float: left;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  border: 0;
  background: #fff;
  border-radius: 3px 0 0 3px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: oblique;
  color: #dbdbdb;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: oblique;
  color: #dbdbdb;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input:-ms-input-placeholder {
  /* IE 10+ */
  font-style: oblique;
  color: #dbdbdb;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input:-moz-placeholder {
  /* Firefox 18- */
  font-style: oblique;
  color: #dbdbdb;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm input:focus {
  outline: 0;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm .login-validation {
  font: bold 15px 'Tahoma';
  margin: 0;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm button {
  font-size: 14px;
  vertical-align: top;
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm button:hover {
  background: #3db8ff;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm button:active,
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .nameContainer .newItemForm button:focus {
  background: #0081d1;
  outline: 0;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .icon {
  position: relative;
  padding-top: 7px;
  font-size: 18px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .icon:before {
  color: black;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .dateModified {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 34px;
  float: right;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .modifiedByContainer {
  margin-top: 10px;
}
.fileManagmentLeftSidebar .contentColumn .fileContentContainer .modifiedbyLabel {
  font-weight: bold;
}
@media (max-width: 320px) {
  .fileManagmentLeftSidebar .contentColumn .fileContentContainer .modifiedbyLabel {
    padding-left: 10px;
  }
}
.fileManagmentLeftSidebar .contentColumn .footer {
  flex-shrink: 0;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: lightgrey;
  padding: 7px;
  position: inherit;
}
.fileManagmentLeftSidebar .contentColumn .footer span {
  padding: 10px;
  line-height: 15px;
}
.fileManagmentLeftSidebar .contentColumn .footer input {
  width: 400px;
}
.fileManagmentLeftSidebar .contentColumn .footer .btn-group {
  padding-left: 10px;
}
.dragOver {
  background-color: lightblue;
  border-color: blue;
}
.modalheader .modal-title {
  font-size: 20px;
}
.modal-body {
  font-size: 13px;
}
.modal-body .newfileheader {
  font-weight: bold;
}
.modal-body .login-validation {
  font-size: 13px;
}
.modal-body .newFileNameTitle {
  font-size: 14px;
}
.modal-body button {
  font-size: 14px;
}
.dragged .btnEditName {
  display: none;
}
.breadcrumbsOverflowButton {
  margin: 0px 10px 0px 0px;
  border: 0;
  background: none;
  outline: none;
}
.breadcrumbsOverflowButton > div {
  padding-left: 10px;
  padding-right: 10px;
}
.btn {
  font-size: 12px !important;
}
.actionBar nav {
  width: 100%;
}
.fixleftmargin {
  margin-left: 0px !important;
}
.fileManagementDropDownText {
  padding: 10px 25%;
}
.fileManamgentActionButtons {
  font-size: 13px;
}
.fileManamgentActionButtons .iconPadding {
  padding-right: 11px;
}
@media only screen and (min-width: 430px) and (max-width: 530px) {
  .fileManamgentActionButtons {
    font-size: 10px;
  }
}
@media only screen and (min-width: 330px) and (max-width: 429px) {
  .fileManamgentActionButtons {
    font-size: 9px;
  }
}
@media only screen and (max-width: 329px) {
  .fileManamgentActionButtons {
    font-size: 8px;
  }
}
#filemangmentpathcontainer .arrows li::before {
  content: '>' !important;
}
#filemangmentpathcontainer .arrows li:first-child {
  margin-right: 5px;
}
#filemangmentpathcontainer .arrows li:first-child::before {
  content: '' !important;
}
