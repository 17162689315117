.heightAndSupportsContainer .wallHeightContainer {
  margin-bottom: 15px;
}
.heightAndSupportsContainer label {
  line-height: 29px;
  white-space: normal;
}
.heightAndSupportsContainer fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 0.7em 0.7em 0.7em !important;
  margin: 0 0 0.7em 0 !important;
  box-shadow: 0px 0px 0px 0px #000;
}
.heightAndSupportsContainer fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.heightAndSupportsContainer fieldset .lblleft {
  margin-left: -14px;
  font-size: 20px;
  font-weight: bold;
}
