/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.wrapText {
  white-space: normal;
}
.loadsContainer {
  float: left;
  position: relative;
  width: 100%;
  top: 10px;
}
.loadsContainer .cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.loadsContainer .rowHeadersContainer {
  font-weight: bold;
  width: 180px;
  float: left;
}
.loadsContainer .rowHeadersContainer .loadsRowHeader {
  height: 30px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.loadsContainer .rowHeadersContainer .loadsRowHeader:first-child {
  border-top: 1px solid #ccc;
}
.loadsContainer .dropdownquickloadbuttonfrench {
  margin-right: 10px;
}
.loadsContainer .dropdownquickloadbuttonenglish {
  margin-right: 50px;
}
.loadsContainer .loadGridColumn {
  width: 185px;
}
.loadsContainer .loadGridColumn .columnHeaderCell .optionsDropDown {
  overflow: visible !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
}
.loadsContainer .loadGridColumn .columnHeaderCell .optionsDropDown .dropdown-menu {
  margin-top: -1px;
  right: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: -6px 6px 6px 0px rgba(0, 0, 0, 0.75);
  width: 179px;
}
.loadsContainer .loadGridColumn .columnHeaderCell .optionsDropDown .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #262626;
}
.loadsContainer .loadGridColumn .columnHeaderCell .btn-group.open .dropdown-toggle {
  box-shadow: initial;
}
.loadsContainer .loadGridColumn div select {
  text-align: center;
  text-align-last: center;
}
.loadsContainer .newLoadContainer:focus {
  outline: 0;
}
.loadsContainer .newLoadContainer .addLoadButtons {
  width: 100%;
  text-align: center;
  height: 30px;
}
.loadsContainer .newLoadContainer .addLoadButtons .newLoad {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 85%;
  height: 28px;
  margin: 0 auto;
}
.loadsContainer .newLoadContainer .addLoadButtons .moreOptions {
  width: 15%;
  height: 28px;
  border-left: 1px solid #ccc;
}
.loadsContainer .newLoadContainer .addLoadButtons .moreOptions button {
  color: green;
  margin: 0 auto;
  padding-top: 5px;
  font-size: 16px;
}
.loadsContainer .newLoadContainer .addLoadButtons div:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #262626;
}
.loadsContainer .newLoadContainer .addLoadButtons div:hover .firstDropdown .subMenuFixedHeight {
  max-height: inherit;
  overflow-y: auto;
}
.loadsContainer .newLoadContainer .addLoadActionButton {
  padding-bottom: 5px;
  width: 100%;
  height: 30px;
  text-align: center;
  padding-top: 5px;
}
.loadsContainer .newLoadContainer div {
  display: inline-flex;
  width: auto;
  position: relative;
  margin: auto;
}
.loadsContainer .newLoadContainer img {
  vertical-align: inherit;
  padding-right: 3px;
  height: 16px;
  margin-top: 1px;
}
.loadsContainer .newLoadContainer .firstDropdown {
  display: none;
  top: 27px;
  left: 6px;
  width: 174px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: -6px 6px 6px 0px rgba(0, 0, 0, 0.75);
}
.loadsContainer .newLoadContainer .firstDropdown li:hover {
  background-color: #f5f5f5;
  color: #262626;
  cursor: pointer;
}
.loadsContainer .newLoadContainer .dropdown-submenu {
  position: relative;
}
.loadsContainer .newLoadContainer .dropdown-submenu > .dropdown-menu {
  top: 0px;
  padding-top: 0px;
  left: 174px;
  margin-top: -1px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
  -webkit-box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.75);
}
.loadsContainer .newLoadContainer .dropdown-submenu > .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #262626;
}
.loadsContainer .newLoadContainer .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.loadsContainer .newLoadContainer .dropdown-submenu:hover > .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #262626;
}
.loadsContainer .newLoadContainer .dropdown-submenu > .dropdownQuickLoadButton:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 3px;
}
.loadsContainer .newLoadContainer .dropdown-submenu:hover > .dropdownQuickLoadButton:after {
  border-left-color: #ffffff;
}
.loadsContainer .newLoadContainer .dropdown-submenu.pull-left {
  float: none;
}
.loadsContainer .newLoadContainer .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.loadsContainer .newLoadContainer .dropdown-submenu.pull-left > .dropdown-menu li:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #262626;
}
.loadsContainer .loadsMessage {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.loadsContainer .singleValueContainer.loadIsOffMember {
  background-color: #FFFF00;
}
.loadsContainer .singleValueContainer.loadIsOffMember input {
  background-color: transparent;
}
.loadsContainer .multiValueContainer.loadIsOffMember input {
  background-color: #FFFF00;
}
.messagesContainer ul {
  padding-left: 12px;
}
.graphicPinnedForLoads {
  float: left;
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.graphicPinnedForLoadsremoveMargin {
  float: left;
  position: relative;
  width: 100%;
}
.dropdownIcons {
  padding: 7px;
  border: 0 !important;
  background: none;
  outline: none;
  height: 16px !important;
  overflow: visible !important;
}
.dropdownCopyButton {
  border-bottom: 0px;
  color: green;
  font-size: 16px;
}
.dropdownSaveButton {
  border-bottom: 0px;
  color: blue;
  font-size: 16px;
}
.dropdownQuickLoadButton {
  border-bottom: 0px;
  color: orange;
  font-size: 16px;
}
.specificPopupWidth {
  max-width: 700px !important;
}
.specificPopupWidth .popover-content {
  padding-top: 2px !important;
}
.specificPopupWidth .popover-content ul {
  padding-top: 0px !important;
  margin-bottom: 5px;
}
.loadEditingBtnContainer {
  display: block;
  margin-top: 10px;
}
.loadEditingBtnContainer .form-control {
  font-size: 12px!important;
  font-weight: 700!important;
  width: 190px;
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.loadEditingBtnContainer .form-control:focus {
  border-color: #4d90fe;
  border-width: 2px;
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
}
.loadEditingBtnContainer button {
  width: 200px;
}
.accordion-toggle:hover {
  color: #000 !important;
}
.accordion-toggle:focus {
  color: #fff !important;
}
.ddlFullWidth {
  width: 100%;
}
.ddlFullWidth .dropdown-menu {
  width: 100% !important;
  min-width: 100% !important;
}
.toTxt {
  text-align: center;
  display: block;
}
.spinner {
  width: 100% !important;
  top: 40%;
}
.addLoadDiv {
  padding-right: 5px !important;
}
.linkReactionDiv {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.divQuickLoadsDropDown {
  padding-left: 5px !important;
}
.disabledTextBlock {
  font-style: italic;
  text-align: center;
  border-bottom: 0px;
}
.labelWrap {
  word-wrap: normal;
  white-space: normal;
}
.deleteLoadDiv {
  padding-right: 0px;
}
.material-switch > input[type="checkbox"] {
  display: none;
}
.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}
.material-switch > label::before {
  background: #000000;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}
@media screen and (max-width: 450px) {
  .addLoadDiv {
    width: 100% !important;
    padding-bottom: 5px !important;
    padding-right: 15px !important;
  }
  .linkReactionDiv {
    width: 50% !important;
    padding-right: 3px !important;
    padding-left: 15px !important;
  }
  .divQuickLoadsDropDown {
    width: 50% !important;
    padding-left: 3px !important;
  }
}
@media screen and (max-width: 412px) {
  .iziToast > .iziToast-body .iziToast-icon {
    top: 18% !important;
  }
}
@media screen and (max-width: 587px) and (min-width: 412px) {
  .iziToast > .iziToast-body .iziToast-icon {
    top: 27% !important;
  }
}
@media screen and (max-width: 350px) {
  .form-control,
  .textLabel {
    font-size: 12px;
  }
  .loadsContainer .btnfont {
    font-size: 11px !important;
  }
}
.deleteButton {
  float: left;
  margin-right: 1vw;
}
.saveAsQuickLoadBtn {
  padding-left: 0;
}
.copyBtn {
  padding-right: 0;
  float: right;
}
.iconSize {
  font-size: 14px;
}
