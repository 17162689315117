.eccentricityContainer {
  margin-left: 0px;
  margin-right: 0px;
}
.eccentricityCheckboxContainer {
  padding-top: 25px;
}
.tributaryWidth {
  width: 230px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.tributaryWidth label {
  margin-top: 7px;
}
.tributaryWidthInput {
  width: 100px;
  float: right;
}
.eccecntricityDropdown {
  width: 210px;
}
