@font-face {
  font-family: 'forteWebFont';
  src: url('/fonts/forteWebFont.eot?qx1cph');
  src: url('/fonts/forteWebFont.eot?qx1cph#iefix') format('embedded-opentype'), url('/fonts/forteWebFont.ttf?qx1cph') format('truetype'), url('/fonts/forteWebFont.woff?qx1cph') format('woff'), url('/fonts/forteWebFont.svg?qx1cph#forteWebFont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="forteIcon-"],
[class*=" forteIcon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'forteWebFont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.forteIcon-ceiling_joist .path1:before {
  content: "\e94f";
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-ceiling_joist .path2:before {
  content: "\e97d";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-ceiling_joist .path3:before {
  content: "\e97e";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-ceiling_joist .path4:before {
  content: "\e97f";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-ceiling_joist .path5:before {
  content: "\e980";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-job-file .path1:before {
  content: "\e924";
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-job-file .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: none;
}
.forteIcon-job-file .path3:before {
  content: "\e926";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-job-file .path4:before {
  content: "\e927";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_hip-valley .path1:before {
  content: "\e928";
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_hip-valley .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_hip-valley .path3:before {
  content: "\e946";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_hip-valley .path4:before {
  content: "\e947";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_hip-valley .path5:before {
  content: "\e948";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_hip-valley .path6:before {
  content: "\e949";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_hip-valley .path7:before {
  content: "\e94a";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_hip-valley .path8:before {
  content: "\e94b";
  margin-left: -1em;
  color: #ffffff;
}
.forteIcon-roof_hip-valley .path9:before {
  content: "\e94c";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_hip-valley .path10:before {
  content: "\e94d";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_hip-valley .path11:before {
  content: "\e94e";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-beam .path1:before {
  content: "\e902";
  color: #aab1b3;
}
.forteIcon-beam .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-beam .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-beam .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-beam .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-beam .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.4;
}
.forteIcon-beam .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.4;
}
.forteIcon-column .path1:before {
  content: "\e909";
  color: #aab1b3;
}
.forteIcon-column .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-column .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-column .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-column .path5:before {
  content: "\e90d";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.4;
}
.forteIcon-column .path6:before {
  content: "\e90e";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.4;
}
.forteIcon-column .path7:before {
  content: "\e90f";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-floor_drop-beam .path1:before {
  content: "\e910";
  color: #ea971f;
}
.forteIcon-floor_drop-beam .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_drop-beam .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_drop-beam .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_drop-beam .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_drop-beam .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_drop-beam .path7:before {
  content: "\e916";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_flush-beam .path1:before {
  content: "\e917";
  color: #aab1b3;
}
.forteIcon-floor_flush-beam .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_flush-beam .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-floor_flush-beam .path4:before {
  content: "\e91a";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_flush-beam .path5:before {
  content: "\e91b";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_flush-beam .path6:before {
  content: "\e91c";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_flush-beam .path7:before {
  content: "\e91d";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-floor_joist .path1:before {
  content: "\e91e";
  color: #ea971f;
}
.forteIcon-floor_joist .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-floor_joist .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_joist .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-floor_joist .path5:before {
  content: "\e922";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-floor_joist .path6:before {
  content: "\e923";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-level .path1:before {
  content: "\e929";
  color: #aab1b3;
}
.forteIcon-level .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-level .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-level .path4:before {
  content: "\e92c";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-level .path5:before {
  content: "\e92d";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-level .path6:before {
  content: "\e92e";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-level .path7:before {
  content: "\e92f";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-level .path8:before {
  content: "\e930";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-level .path9:before {
  content: "\e931";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-post_free-standing .path1:before {
  content: "\e932";
  color: #aab1b3;
}
.forteIcon-post_free-standing .path2:before {
  content: "\e933";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-post_free-standing .path3:before {
  content: "\e934";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_drop-beam .path1:before {
  content: "\e935";
  color: #aab1b3;
}
.forteIcon-roof_drop-beam .path2:before {
  content: "\e936";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_drop-beam .path3:before {
  content: "\e937";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_drop-beam .path4:before {
  content: "\e938";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_drop-beam .path5:before {
  content: "\e939";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_drop-beam .path6:before {
  content: "\e93a";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_drop-beam .path7:before {
  content: "\e93b";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_drop-beam .path8:before {
  content: "\e93c";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_flush-beam .path1:before {
  content: "\e93d";
  color: #aab1b3;
}
.forteIcon-roof_flush-beam .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_flush-beam .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_flush-beam .path4:before {
  content: "\e940";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_flush-beam .path5:before {
  content: "\e941";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_flush-beam .path6:before {
  content: "\e942";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_flush-beam .path7:before {
  content: "\e943";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_flush-beam .path8:before {
  content: "\e944";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_joist .path1:before {
  content: "\e950";
  color: #aab1b3;
}
.forteIcon-roof_joist .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_joist .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_joist .path4:before {
  content: "\e953";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_joist .path5:before {
  content: "\e954";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-roof_joist .path6:before {
  content: "\e955";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_joist .path7:before {
  content: "\e956";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-roof_joist .path8:before {
  content: "\e957";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-roof_joist .path9:before {
  content: "\e958";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_column .path1:before {
  content: "\e959";
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path4:before {
  content: "\e95c";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path5:before {
  content: "\e95d";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path6:before {
  content: "\e95e";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path7:before {
  content: "\e95f";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_column .path8:before {
  content: "\e960";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_column .path9:before {
  content: "\e961";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_column .path10:before {
  content: "\e962";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_column .path11:before {
  content: "\e963";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_header .path1:before {
  content: "\e964";
  color: #aab1b3;
}
.forteIcon-wall_header .path2:before {
  content: "\e965";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_header .path3:before {
  content: "\e966";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_header .path4:before {
  content: "\e967";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_header .path5:before {
  content: "\e968";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_stud .path1:before {
  content: "\e969";
  color: #ea971f;
}
.forteIcon-wall_stud .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_stud .path3:before {
  content: "\e96b";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_stud .path4:before {
  content: "\e96c";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_stud .path5:before {
  content: "\e96d";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_stud .path6:before {
  content: "\e96e";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_stud .path7:before {
  content: "\e96f";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_stud .path8:before {
  content: "\e970";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_wind-still .path1:before {
  content: "\e971";
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path3:before {
  content: "\e973";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path4:before {
  content: "\e974";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path5:before {
  content: "\e975";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path6:before {
  content: "\e976";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_wind-still .path7:before {
  content: "\e977";
  margin-left: -1em;
  color: #ea971f;
}
.forteIcon-wall_wind-still .path8:before {
  content: "\e978";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path9:before {
  content: "\e979";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_wind-still .path10:before {
  content: "\e97a";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-wall_wind-still .path11:before {
  content: "\e97b";
  margin-left: -1em;
  color: #aab1b3;
  opacity: 0.5;
}
.forteIcon-wall_wind-still .path12:before {
  content: "\e97c";
  margin-left: -1em;
  color: #aab1b3;
}
.forteIcon-matman:before {
  content: "\e901";
}
.forteIcon-selectAll:before {
  content: "\e900";
}
