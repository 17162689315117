.textArea {
  resize: none;
}
.smallInput {
  width: 50px;
}
.displayInline {
  display: inline;
}
.reportDamage {
  margin: 10px;
}
.reportDamage p {
  margin-left: 5px;
}
.reportDamageNotes {
  color: blue;
}
.reportDamageTitle label {
  font-size: 20px;
  font-weight: normal;
}
.input-validation {
  color: red;
}
.reportDamageContainer .modal-dialog {
  width: 800px;
}
.characterCount {
  float: right;
  color: #7a7a7a;
}
