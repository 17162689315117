/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
#AddMemberButtons_HorizontalView {
  position: absolute;
  left: 0px;
  z-index: 10000;
  background: white;
  width: 1206px;
  box-shadow: 0px 12px 18px 1px rgba(0, 0, 0, 0.75);
}
#AddMemberButtons_HorizontalView.ng-hide-add {
  -webkit-animation: fadeOutLeft 0.3s;
  -moz-animation: fadeOutLeft 0.3s;
  -ms-animation: fadeOutLeft 0.3s;
  animation: fadeOutLeft 0.3s;
}
#AddMemberButtons_HorizontalView.ng-hide-remove {
  -webkit-animation: fadeInLeft 0.3s;
  -moz-animation: fadeInLeft 0.3s;
  -ms-animation: fadeInLeft 0.3s;
  -o-animation: fadeInLeft 0.3s;
  animation: fadeInLeft 0.3s;
}
.addMember-splash {
  z-index: 2 !important;
  opacity: 0.7;
  padding-top: 75px;
}
.closeGraphic {
  background: transparent;
  border: 0px;
  float: right;
  font-weight: bold;
  color: black;
  text-shadow: none;
  opacity: none;
  padding: 0px;
  margin-top: 0px;
}
.closeGraphic span i {
  font-size: 30px;
}
.nodeDeleteModal {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 200px;
  margin-top: -100px;
  margin-left: -150px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
}
.nodeDeleteModal .nodeDeleteContent {
  padding: 15px;
  flex: 1;
}
.nodeDeleteModal .nodeDeleteFooter {
  padding: 15px;
  flex-shrink: 0;
  height: calc(35px + (2*15px));
}
.nodeDeleteModal .nodeDeleteFooter button {
  margin-left: 10px;
  float: right;
}
.nodeDeleteModal .nodeDeleteHeader {
  font-size: 18px;
  flex-shrink: 0;
  color: #ffffff;
  padding: 15px;
  background-color: #0d3e69;
}
.nodeDeleteModal .nodeDeleteHeader .closeButton {
  cursor: pointer;
  float: right;
  margin-top: -5px;
}
@media only screen and (orientation: portrait) {
  .rotateMobileGraphic {
    transform: rotate(90deg);
    background: transparent;
    align-content: center;
    padding: 0px;
  }
}
@media only screen and (orientation: landscape) {
  .rotateMobileVerticalGraphic {
    transform: rotate(90deg);
    background: transparent;
    align-content: center;
    padding: 0px;
  }
  .sizeVerticalMobileGraphic {
    height: 77vh;
  }
  .sizeVerticalMobileGraphic img {
    height: 85vw;
    width: 85vh;
    /* margin: 0 auto; */
    margin-left: 25vw;
    margin-top: -20vw;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
@media only screen and (orientation: portrait) {
  .sizeMobileGraphic {
    margin-top: 15vh;
    margin-left: -20vh;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 85vh;
    height: 85vw;
  }
  .sizeVerticalMobileGraphic {
    height: 150vw;
  }
  .sizeVerticalMobileGraphic img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
}
@media only screen and (orientation: portrait) {
  .sizeMobileGraphicLarge {
    margin-top: 22vh;
    margin-left: -22vh;
    margin-right: 0px;
    margin-bottom: 0px;
    width: 85vh;
    height: 85vw;
  }
}
