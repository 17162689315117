/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.floorPerformance {
  max-width: 320px;
}
.floorPerformance .floorPerformanceGraphic {
  margin: 0 0 13px 100px;
}
.floorPerformance fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
.floorPerformance fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.floorPerformance fieldset .fontNormalLabel {
  font-weight: normal;
}
.floorPerformance fieldset .lblminimumTJProRating {
  padding-top: 20px;
}
.floorPerformance fieldset .chkFloorPerformance {
  height: 18px;
  margin: 0px;
  margin-left: -9px;
  margin-right: 5.5px;
}
.floorPerformance fieldset .chkFloorPerformanceModifier {
  height: 18px;
  margin: 0px;
  margin-left: -38px;
  margin-right: 6px;
}
.floorPerformance fieldset .spanChk {
  margin-left: -15px;
  margin-bottom: 0px;
  font-weight: normal;
  white-space: nowrap;
}
.floorPerformance fieldset .labelChk {
  margin-left: 20px;
  margin-bottom: 0px;
  font-weight: normal;
  white-space: nowrap;
}
.floorPerformance fieldset .divUserDefine {
  margin-left: 0px;
}
.floorPerformance fieldset .spanUserDefine {
  margin-top: 7px;
  white-space: nowrap;
}
.floorPerformance fieldset .lblleft {
  margin-left: -14px;
}
.floorPerformance fieldset .sliderContainer {
  display: flex;
  margin-bottom: 10px;
}
.floorPerformance fieldset .sliderContainer input[type="range"] {
  flex: 16;
}
.floorPerformance fieldset .sliderContainer span {
  flex: 1;
}
.floorPerformance fieldset .sliderContainer span:last-of-type {
  text-align: right;
}
.floorPerformance fieldset .spanwithoutwhiteSpace {
  white-space: unset;
}
.paddingfix {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px;
}
.floorTopMargin {
  margin-top: 10px;
}
