/* inject:lessImports */
/* endinject */
.modal-dialog {
  width: 1080px;
}
.modal-dialog .lrModalBody {
  display: flex;
  flex-direction: row;
  height: 515px;
  min-height: 100%;
}
.modal-dialog .lrModalBody .lrLeftColumn {
  flex-shrink: 0;
  width: 236px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree {
  height: 485px;
  border: 2px solid black;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree ol li div {
  color: #000000;
  font-weight: bold;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .disabledMemberNode {
  color: rgba(95, 95, 95, 0.5);
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .disabledMemberNode i {
  background-image: none !important;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .disabledMemberNode i:after {
  font-family: FontAwesome;
  content: "\f071";
  font-style: normal;
  font-size: 18px;
  color: #ECA136;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .treeRoot.selected .nameContainer .nameAndEditButton,
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .level.selected .nameContainer .nameAndEditButton,
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .member.selected .nameContainer .nameAndEditButton {
  background: #beebff;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .treeRoot {
  margin: 0px;
  padding: 0px;
  height: 33px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .treeRoot .nameContainer .nameAndEditButton {
  margin-left: 0px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .level {
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
  height: 33px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .level .nameContainer .renameItemForm .renameItemText {
  width: calc(100% - 52px);
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .member {
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
  height: 33px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .collapseButton {
  float: left;
  margin-right: 5px;
  margin-top: 6px;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .nameDiv {
  padding: 0px;
  margin: 0px;
  padding: 7px;
  margin-left: 7px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .nameDiv.selected {
  background: #beebff;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .nameDiv.selected:hover {
  background: #beebff;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .nameDiv:hover {
  background: #e7f4f9;
}
.modal-dialog .lrModalBody .lrLeftColumn .memberManagementTree .nohover :hover {
  background-color: white;
}
.modal-dialog .lrModalBody .lrRightColumn {
  width: calc(100% - 236px);
  display: flex;
  flex-direction: column;
}
.modal-dialog .lrModalBody .lrRightColumn .rightContentMessage {
  height: 100%;
  margin: 0 auto;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.modal-dialog .lrModalBody .lrRightColumn .dialogGraphic {
  width: 800px;
  margin-top: 5px;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm {
  width: 750px;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm .supportSelection {
  width: 60px;
  margin-top: -6px;
  margin-bottom: 6px;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm .row {
  margin-top: 2px;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm .row .disabledText {
  display: none;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm .row:first-of-type {
  margin-top: 0px;
}
.modal-dialog .lrModalBody .lrRightColumn .lrRightForm .toText {
  text-align: center;
}
.checkboxLabels {
  margin-right: 35px;
}
.radioButton {
  height: 35px;
}
