.seismicLoadingContainer .seismicNote {
  margin-top: 30px;
  margin-left: 28px;
  width: 335px;
}
.seismicLoadingContainer .omegaInputContainer {
  margin-top: 30px;
  margin-left: 28px;
}
.seismicLoadingContainer .omegaInputContainer .omegaLabel {
  font-weight: bold;
}
.seismicLoadingContainer .omegaInputContainer .omegaInput {
  width: 330px;
}
.seismicLoadingContainer .sdsInputContainer {
  margin-top: 30px;
  margin-left: 28px;
}
.seismicLoadingContainer .sdsInputContainer .sdsLabel {
  font-weight: bold;
}
.seismicLoadingContainer .sdsInputContainer .sdsInput {
  width: 330px;
}
.seismicLoadingContainer .categoryInputContainer {
  margin-top: 30px;
  margin-left: 28px;
}
.seismicLoadingContainer .categoryInputContainer .categoryLabel {
  font-weight: bold;
}
.seismicLoadingContainer .categoryInputContainer .categorySelect {
  width: 330px;
}
