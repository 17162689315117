@media (orientation: portrait) {
  .boxblock {
    min-height: 160px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-bearingWidth {
    min-height: 75px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-supportPliesLabelDDL {
    min-height: 80px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-connectorDialogSupportDepth {
    min-height: 150px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
    margin-bottom: 10px;
  }
  .boxblock-skewDialogFieldset {
    min-height: 195px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-pitchDialogFieldset {
    min-height: 147px;
    min-width: 220px;
    padding-left: 0px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-offsetDialogFieldset {
    min-height: 160px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-concealedFlangeFieldset {
    min-height: 180px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-bearingWidthDDLRight {
    min-height: 80px;
    min-width: 182px;
    float: none;
    margin: 0 auto;
  }
  .boxblock-bearingWidthDDLLeft {
    min-height: 80px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
  }
}
@media only screen and (orientation: landscape) {
  .boxblock-showSupportPliesDDL {
    min-height: 70px;
    min-width: 220px;
  }
  .boxblock-skewDialogFieldset {
    min-height: 195px;
    min-width: 220px;
  }
  .boxblock-offsetDialogFieldset {
    min-height: 160px;
    min-width: 220px;
    margin-top: 10px;
  }
  .boxblock-pitchDialogFieldset {
    min-width: 220px;
  }
  .boxblock-bearingWidthDDLRight {
    min-height: 80px;
    min-width: 204px;
    float: left;
    margin: 0 auto;
  }
  .boxblock-bearingWidthDDLLeft {
    min-height: 180px;
    min-width: 220px;
    float: left;
    margin: 0 auto;
  }
}
@media (min-width: 360px) and (orientation: landscape) {
  .boxblock-bearingWidthDDL {
    min-height: 220px;
    min-width: 220px;
    float: left;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-connectorDialogSupportDepth {
    min-height: 205px;
    min-width: 220px;
  }
}
@media (max-width: 481px) and (max-height: 321px) and (orientation: landscape) {
  .boxblock {
    min-height: 160px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-bearingWidth {
    min-height: 220px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-connectorDialogSupportDepth {
    min-height: 150px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
    margin-bottom: 10px;
  }
  .boxblock-skewDialogFieldset {
    min-height: 195px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-pitchDialogFieldset {
    min-height: 147px;
    min-width: 220px;
    padding-left: 0px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-offsetDialogFieldset {
    min-height: 160px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-concealedFlangeFieldset {
    min-height: 180px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
    padding: 0px;
  }
  .boxblock-bearingWidthDDLRight {
    min-height: 80px;
    min-width: 182px;
    float: none;
    margin: 0 auto;
  }
  .boxblock-bearingWidthDDLLeft {
    min-height: 80px;
    min-width: 220px;
    float: none;
    margin: 0 auto;
  }
}
