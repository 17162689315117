/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.solutionsTabContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.solutionsTabContainer input[type="checkbox"] {
  width: 18px;
  margin: 0px;
  margin-right: 5px;
  float: left;
  height: 18px;
}
.solutionsTabContainer .onlyShowPassingContainer {
  float: left;
  margin-top: 15px;
  margin-bottom: 18px;
}
.solutionsTabContainer .resultFailed {
  color: #E44C36;
}
.solutionsTabContainer .solutionTitle {
  float: left;
}
.solutionsTabContainer .sortList {
  padding-left: 10px;
  min-width: 119px;
}
.solutionsTabContainer .sortList .sortedItem {
  margin-top: 5px;
}
.solutionsTabContainer .sortedbyDiv {
  margin-top: 5px;
}
.solutionsTabContainer .sortedbyDiv .sortDescending:after {
  font-family: FontAwesome;
  content: "\f0dd";
  padding-left: 5px;
}
.solutionsTabContainer .sortedbyDiv .fa-sort {
  margin-left: 8px;
}
.solutionsTabContainer .sortedbyDiv .sortAscending:after {
  font-family: FontAwesome;
  content: "\f0de";
  padding-left: 5px;
}
.solutionsTabContainer .sortedbyDiv .nonSortableColumn {
  color: #a2a2a2;
  white-space: nowrap;
}
.solutionsTabContainer .sortedbyDiv .nonSortableColumn:hover {
  cursor: default;
  background-color: initial;
}
.solutionsTabContainer .designSummaryContainer h4 .resultText {
  padding-left: 20px;
  color: #009d00;
}
.solutionsTabContainer .designSummaryContainer h4 .resultFailed {
  color: #E44C36;
}
.solutionsTabContainer .paddingForScroll {
  padding-right: 19px;
}
.solutionsTabContainer .headerContainer {
  border-bottom: 2px solid #ddd;
}
.solutionsTabContainer .tableScroll {
  height: 100%;
  overflow-y: auto;
}
.solutionsTabContainer .tableScroll tr {
  cursor: pointer;
}
.solutionsTabContainer .allSolutionsContainer {
  flex: 3;
}
.solutionsTabContainer .allSolutionsContainer .panel-body:hover {
  background-color: #dfdfdf !important;
}
.solutionsTabContainer .allSolutionsContainer .labelmember {
  white-space: nowrap;
}
.solutionsTabContainer .allSolutionsContainer .collapseSolution {
  margin-bottom: 5px;
}
.solutionsTabContainer .allSolutionsContainer .iconResult {
  float: right;
}
.solutionsTabContainer .allSolutionsContainer .iconResultSuccess {
  color: #009d00;
}
.solutionsTabContainer .allSolutionsContainer .iconResultError {
  color: red;
}
.solutionsTabContainer .allSolutionsContainer .removePaddingLeft {
  padding-left: 0;
}
.solutionsTabContainer .allSolutionsContainer .table-hover > tbody > tr:hover {
  background-color: #e3e3e3;
}
.solutionsTabContainer .allSolutionsContainer table thead {
  color: transparent;
}
.solutionsTabContainer .allSolutionsContainer .specialOrder {
  background-color: #ffdb99;
}
.solutionsTabContainer .allSolutionsContainer .specialOrder:hover {
  background-color: #ffc966 !important;
}
.solutionsTabContainer .allSolutionsContainer .unavailable {
  background-color: #ff9999;
}
.solutionsTabContainer .allSolutionsContainer .unavailable:hover {
  background-color: #ff6666 !important;
}
.solutionsTabContainer .allSolutionsContainer .selected {
  background-color: #beebff !important;
}
.solutionsTabContainer .allSolutionsContainer .selected:hover {
  background-color: #bedeff !important;
}
.solutionsTabContainer .allSolutionsContainer .selected.specialOrder {
  background-color: #ffc966 !important;
}
.solutionsTabContainer .allSolutionsContainer .selected.specialOrder:hover {
  background-color: #ffb733 !important;
}
.solutionsTabContainer .allSolutionsContainer .selected.unavailable {
  background-color: #ff6666 !important;
}
.solutionsTabContainer .allSolutionsContainer .selected.unavailable:hover {
  background-color: #ff3333 !important;
}
.suggested:before {
  content: "\f005";
  color: #4300af;
  font-family: fontawesome;
  text-align: center;
  width: 1.25em;
  display: inline-block;
}
.table > tbody > tr > td {
  padding: 5px 8px 4px 8px;
}
.table > thead > tr > th {
  padding: 4px 8px 4px 8px;
}
.solutionTitle {
  font-size: 16px;
  color: #000;
}
@media (max-width: 321px) and (orientation: portrait) {
  .solutionBlock {
    font-size: 12px;
  }
}
