/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
@keyframes greyToRed {
  0% {
    color: #7a7a7a;
  }
  50% {
    color: #E44C36;
  }
  100% {
    color: #7a7a7a;
  }
}
.hideAddFileFormContainer {
  visibility: hidden;
}
.newFileModel .modalheader .modal-title {
  font-size: 20px;
}
.newFileModel .modal-body {
  font-size: 14px;
}
.newFileModel .modal-body .newfileheader {
  font-weight: bold;
}
.newFileModel .modal-body .login-validation {
  font-size: 13px;
}
.newFileModel .modal-body .newFileNameTitle {
  font-size: 14px;
}
.newFileModel .modal-body button {
  font-size: 13px;
}
.openFileTabHeader {
  font-weight: bold;
}
.login-validation {
  color: #E44C36;
}
.modal-title span {
  font-size: 14px;
}
.headerListIcon {
  font-size: 13px;
  margin-right: 5px;
}
.materialsManagerIcon {
  margin-right: 7.5px;
  margin-left: 1.5px;
  font-size: 13px;
}
.fileManagerIcon {
  font-size: 12px;
  margin-right: 6px;
}
.helpIcon {
  font-size: 15px;
  margin-right: 5.5px;
}
.ahelp {
  padding-left: 12px !important;
}
.headerLangIcon {
  font-size: 13px;
  margin-right: 6px ;
}
.maxHeight {
  max-height: 370px !important;
}
.navbar-collapse.in {
  overflow-y: hidden;
}
