/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
#lablePadding {
  padding-top: 10px;
}
.listItemDrag {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: 0.8;
  height: 30px;
  font-weight: bold;
  list-style-type: none;
}
.listItemDrag .disabled {
  background-color: lightgrey;
}
.settings-title label {
  font-size: 18px;
  font-weight: normal;
}
.settings_container {
  display: flex;
}
.help_icon_settings {
  float: right;
}
.help_icon_profile {
  float: right;
}
.settings_leftColumn {
  flex-shrink: 0;
  width: 165px;
  border-right: 1px solid #d9d9d9;
}
.settings_leftColumn .nav-pills > li > a {
  font-weight: bold;
  color: inherit;
}
.settings_leftColumn .nav > li > a:hover,
.settings_leftColumn .nav > li > a:focus {
  background-color: inherit;
  cursor: pointer;
}
.settings_leftColumn .selected,
.settings_leftColumn .selected > li > a:hover {
  background-color: #4285f4 !important;
  color: white;
}
.settings_rightColumn {
  flex: 1;
  padding: 0 15px;
}
.settings_rightColumn .floatLeft {
  float: left;
}
.settings_rightColumn .formElement {
  width: 310px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.settings_rightColumn .formElement label {
  width: 100%;
}
.settings_rightColumn .settingsTabContent {
  display: none;
}
.settings_rightColumn .settingsTabContent .mainSettings {
  margin: 10px;
  height: 490px;
}
.settings_rightColumn .settingsTabContent .mainSettings #uOMLengthMeasure {
  width: 350px;
}
.settings_rightColumn .settingsTabContent .mainSettings .productComparisonContainer {
  padding-top: 20px;
}
.settings_rightColumn .settingsTabContent .mainSettings .productComparisonContainer #productComparison {
  width: 350px;
}
.settings_rightColumn .settingsTabContent .mainSettings .productComparisonContainer #productComparison:disabled {
  background-color: #eee;
  opacity: 1;
  cursor: not-allowed;
}
.settings_rightColumn .settingsTabContent .mainSettings .exportOptionL1 {
  display: block;
  font-size: 20px;
}
.settings_rightColumn .settingsTabContent .mainSettings .exportOptionL2 {
  display: block;
  font-size: 10px;
}
.settings_rightColumn .settingsTabContent .mainSettings .exportOption {
  width: auto;
  height: 35px;
  margin: 0px;
  padding: 0px;
  display: inline;
}
.settings_rightColumn .settingsTabContent .mainSettings .exportOption .exportOptionI {
  height: 30px;
  width: 350px;
  padding: 0px;
  margin: 0px;
}
.settings_rightColumn .settingsTabContent .mainSettings .exportOption .exportOptionB {
  height: 30px;
  width: 40px;
  padding: 0px;
  margin: 0px;
}
.settings_rightColumn .settingsTabContent .mainSettings .languageL {
  display: block;
}
.settings_rightColumn .settingsTabContent .mainSettings .language {
  width: auto;
  display: block;
}
.settings_rightColumn .settingsTabContent .mainSettings .jobSetting {
  display: block;
}
.settings_rightColumn .settingsTabContent .mainSettings .jobSettingsm {
  font-size: 9px;
}
.settings_rightColumn .selected {
  display: inherit;
}
.resetDefaultsMessage {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  margin-top: 7px;
}
.greenText {
  color: #69a323;
}
.DeflectionFloor .allLegend {
  pointer-events: none;
  user-select: none;
}
.DeflectionFloor .lefthalf {
  border-style: none;
  width: 40%;
  max-height: 458px;
  min-height: 458px;
  float: left;
}
.DeflectionFloor .lefthalf .addNewQuickLoadBtn {
  height: 25px;
  width: 25px;
  display: inline;
  padding: 2px;
  color: green;
}
.DeflectionFloor .lefthalf .addNewQuickLoadBtn:hover {
  background-color: #f5f5f5;
}
.DeflectionFloor .lefthalf .deleteQuickLoadBtn {
  height: 25px;
  width: 25px;
  display: inline;
  padding: 2px;
  color: red;
}
.DeflectionFloor .lefthalf .deleteQuickLoadBtn:hover {
  background-color: #f5f5f5;
}
.DeflectionFloor .lefthalf .moveQuickLoadBtn {
  height: 25px;
  width: 25px;
  display: inline;
  padding: 2px;
  color: black;
}
.DeflectionFloor .lefthalf .moveQuickLoadBtn:hover {
  background-color: #f5f5f5;
}
.DeflectionFloor .lefthalf .loadsListDiv {
  border: 1px;
  border-style: solid;
  min-height: 434px;
  max-height: 434px;
  padding: 0;
  margin-top: 5px;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList {
  list-style: none;
  border-style: none;
  left: 0px;
  min-height: 432px;
  max-height: 432px;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList .quickLoadListItems {
  height: 30px;
  padding-left: 10px;
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList .quickLoadListItems:not(.current):not(.disabled):hover {
  background-color: #f5f5f5;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList .quickLoadListItems div {
  width: 323px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList .disabled {
  background-color: lightgrey;
  cursor: not-allowed;
}
.DeflectionFloor .lefthalf .loadsListDiv .loadsList .current {
  background-color: #4285f4;
  color: white;
}
.DeflectionFloor .lefthalf .hideRightClickMenu {
  display: none;
}
.DeflectionFloor .lefthalf .showRightClickMenu {
  display: block;
  width: auto;
  z-index: 1;
  padding: 2px;
  margin: 2px;
  position: absolute;
  background-color: white;
  border-color: black;
  border-style: solid;
  border: 1px;
}
.DeflectionFloor .lefthalf .showRightClickMenu ul {
  padding: 0px;
}
.DeflectionFloor .lefthalf .showRightClickMenu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.DeflectionFloor .lefthalf .showRightClickMenu li:hover {
  background-color: lightblue;
  border-color: blue;
  border-style: solid;
  border: 1px;
  cursor: default;
}
.DeflectionFloor .righthalf {
  border-style: none;
  width: 58%;
  max-height: 458px;
  min-height: 458px;
  float: right;
}
.DeflectionFloor .righthalf .noQuickLoadMsg {
  height: 458px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.DeflectionFloor .righthalf .loadNameLabel {
  font-weight: bold;
}
.DeflectionFloor .righthalf .loadName {
  width: 366px;
  border: 1px solid #ccc;
  height: 25px;
  padding: 0px 0px 0px 5px;
}
.DeflectionFloor .righthalf .loadName:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.DeflectionFloor .righthalf .buidingField {
  width: 100%;
  font-size: 10px;
  border: 2px;
  border-style: groove;
}
.DeflectionFloor .righthalf .buidingField .buildingLegend {
  font-size: 10px;
  padding: 2px;
  margin: 0;
  pointer-events: none;
}
.DeflectionFloor .righthalf .buidingField .buildingInput1 {
  position: relative;
  padding-left: 30px;
  display: inline-block;
}
.DeflectionFloor .righthalf .buidingField .buildingInput1 label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                                  not supported by any browser */
}
.DeflectionFloor .righthalf .buidingField .buildingInput2 {
  position: relative;
  display: inline-block;
  float: right;
  padding-right: 30px;
}
.DeflectionFloor .righthalf .buidingField .buildingInput2 label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                                  not supported by any browser */
}
.DeflectionFloor .righthalf .tableLabel {
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                          not supported by any browser */
}
.DeflectionFloor .righthalf .ACLForm .ACLField {
  width: 100%;
  font-size: 10px;
  border: 2px;
  border-style: groove;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .ACLLegend {
  font-size: 15px;
  padding: 2px;
  margin: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                                  not supported by any browser */
}
.DeflectionFloor .righthalf .ACLForm .ACLField .firstDivACL {
  height: 50%;
  width: 100%;
  padding-top: 3px;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .firstDivACL .labelACL1 {
  font-size: 12px;
  font-weight: 100;
  padding-left: 20px;
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                                      not supported by any browser */
}
.DeflectionFloor .righthalf .ACLForm .ACLField .firstDivACL .ACLLoads1 {
  padding-right: 20px;
  float: right;
  width: 85px;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .firstDivACL .ACLLoads1 .loadsInput1 {
  width: 35px;
  font-size: 10px;
  pointer-events: all;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .firstDivACL .ACLLoads1 .loadsInput1:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .secondDivACL {
  height: 50%;
  width: 100%;
  display: inline-block;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .secondDivACL .labelACL2 {
  font-size: 12px;
  font-weight: 100;
  padding-left: 20px;
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                                      not supported by any browser */
}
.DeflectionFloor .righthalf .ACLForm .ACLField .secondDivACL .ACLLoads2 {
  padding-right: 20px;
  float: right;
  postion: relative;
  width: 85px;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .secondDivACL .ACLLoads2 .loadsInput2 {
  width: 35px;
  font-size: 10px;
  pointer-events: all;
}
.DeflectionFloor .righthalf .ACLForm .ACLField .secondDivACL .ACLLoads2 .loadsInput2:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.fixedLoadTable {
  width: 100%;
  max-width: 366px;
  border: 1px solid #ccc;
  position: unset;
  margin-top: 10px;
  display: flex;
}
.fixedLoadTable .headersContainer .rowHeadersContainer {
  font-weight: bold;
  max-width: 180px;
  width: 33vw;
}
.fixedLoadTable .headersContainer .rowHeadersContainer .rowHeader {
  padding-left: 3px;
  height: 25px;
  border-bottom: 1px solid #ccc;
}
.fixedLoadTable .headersContainer .rowHeadersContainer .rowHeader.deSelected {
  background-color: transparent;
}
.fixedLoadTable .headersContainer .rowHeadersContainer .rowHeaderComments {
  padding-left: 3px;
  height: 50px;
  padding: auto;
}
.fixedLoadTable .headersContainer .rowHeadersContainer .rowHeaderComments.deSelected {
  background-color: transparent;
}
.fixedLoadTable .columnContainer {
  display: flex;
  width: 100%;
  max-width: 200px;
  flex-wrap: nowrap;
  display: block;
  white-space: nowrap;
}
.fixedLoadTable .columnContainer .gridsContainer {
  width: 100%;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn {
  height: 25px;
  width: 100%;
  padding: auto;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn select {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
  text-align-last: center;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn select:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .singleValueContainer input {
  border: none;
  width: 100%;
  text-align: center;
  padding: 0px;
  height: 24px;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .singleValueContainer input:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .multiValueContainer {
  display: inline-flex;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .multiValueContainer div {
  text-align: center;
  width: 20%;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .multiValueContainer input {
  border: none;
  width: 40%;
  text-align: center;
  padding: 0px;
  height: 24px;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumn .multiValueContainer input:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumnComments {
  height: 50px;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumnComments textarea {
  resize: none;
  padding-left: 3px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-left: 1px solid #ccc;
  border-right: none;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  /* For IE and modern versions of Chrome */
  -moz-box-sizing: border-box;
  /* For Firefox                          */
  -webkit-box-sizing: border-box;
  /* For Safari                           */
  text-align: center;
}
.fixedLoadTable .columnContainer .gridsContainer .gridColumnComments textarea:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.fixedLoadTable .inputValue {
  width: 100%;
  padding: 0;
  margin: 0;
  border-style: none;
  text-align: center;
}
input:disabled {
  background-color: #eee;
  color: #747474;
  border-color: #ddd;
}
.rightContainer {
  max-width: 370px;
  margin: auto;
  padding-right: 0;
  padding-left: 0;
}
.actionButtonTop {
  padding-bottom: 2vw;
  width: 100% !important;
}
.actionButtonTop .addIcon {
  color: green;
}
.actionButtonTop .deleteIcon {
  color: red;
}
.custompanelcontainer {
  width: 100% !important;
}
.concentratedLiveLoadContainer input {
  width: 80%;
  float: left;
}
.concentratedLiveLoadContainer div {
  float: left;
  font-size: 15px;
  line-height: 30px;
  padding-left: 5px;
}
.LoadsField2 {
  font-size: 10px;
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 10px;
}
.LoadsField2 legend {
  width: auto;
  font-size: 11px;
  font-weight: bold;
  padding: 5px;
  border-bottom: 0px;
  margin-bottom: 0px;
}
.LoadsField2 .LoadsCheck {
  padding-left: 20px;
}
.LoadsField2 .LoadsCheck input {
  width: 15px;
  margin: 0px;
  margin-right: 5px;
  float: left;
  height: 15px;
}
.LoadsField2 .LoadsCheck label {
  cursor: pointer;
  font-size: 12px;
  font-weight: 100;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                          not supported by any browser */
}
.LoadsField2L {
  font-size: 3vw;
  font-weight: bold;
  padding-top: 5px;
  padding: 2px;
  margin: 0;
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}
.buildingClassContainer {
  padding-top: 5px;
  font-weight: bold;
  margin-top: 10px;
}
.buildingClassContainer label:first-of-type {
  padding-right: 10px;
}
.panel-group .panel .panel-heading .panel-title .accordion-toggle {
  color: #000 !important;
}
