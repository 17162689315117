.holesContainer {
  float: left;
  position: relative;
  width: 100%;
}
.holesContainer .inputNA {
  background-color: #eee;
}
.holesContainer .holeGridColumn {
  width: 140px;
}
.holesContainer .holeGridColumn .gridCheckboxContainer > input[type="checkbox"] {
  width: 87%;
}
.holesContainer .holeGridColumn div select {
  text-align: center;
  text-align-last: center;
}
.holesContainer .addHoleButton {
  width: 190px;
}
.holesContainer .addHoleButton:focus {
  outline: 0;
}
.holesContainer .addHoleButton:hover {
  cursor: pointer;
}
.holesContainer .addHoleButton div {
  display: inline-flex;
  width: auto;
  position: relative;
  top: -1px;
}
.holesContainer .addHoleButton div div {
  top: -2px;
}
.holesContainer .addHoleButton img {
  vertical-align: inherit;
  padding-right: 3px;
  height: 16px;
}
.holesContainer .copyHoleBtn {
  float: right;
}
.holeSelect,
.disabledTextBlock {
  text-align: center;
  text-align-last: center;
}
.labelholes {
  float: left !important;
  white-space: nowrap;
}
.deleteButton {
  float: left;
  margin-right: 1vw;
}
.copyButton {
  float: right;
}
.addHoleDiv {
  vertical-align: middle;
  text-align: center;
  padding-top: 2px;
}
.iconAddHole {
  font-size: 16px ;
  padding-right: 11px;
}
.iconCopyDeleteHole {
  font-size: 14px ;
}
