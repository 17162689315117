/* inject:lessImports */
/* endinject */
#leftFooter .btn-group button {
  height: 100%;
}
#jobTreeButtons .btn-default[disabled] {
  background-color: #000;
}
#jobTreeButtons .btn-default:focus {
  outline: none;
}
#jobTreeContainer {
  font-size: 12px;
  top: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.bgBeam {
  background-image: url(/images/Beam.png);
}
.bgRoofJoist {
  background-image: url(/images/RoofJoist.png);
}
.bgFloorJoist {
  background-image: url(/images/FloorJoist.png);
}
.bgColumn {
  background-image: url(/images/Column.png);
}
.bgStud {
  background-image: url(/images/Stud.png);
}
.jstree-default .jstree-icon:empty {
  width: 19px;
}
#jobTreeContainer .jstree-container-ul > .jstree-node > .jstree-ocl {
  display: none;
}
#jobTreeContainer .jstree-container-ul > li > .jstree-children > li {
  margin-left: 0;
}
.jstree-node .jstree-leaf {
  margin-left: 10px;
}
#root_anchor {
  width: 100%;
}
#AddMemberButtons {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(66, 66, 66, 0.95);
}
#jobTreeButtons {
  top: 0;
  width: 100%;
  height: 100%;
}
#btnCancelAddMember {
  top: 0;
  opacity: 0;
  width: 100%;
}
a.hiddenanchor {
  display: none;
}
.jstree-node {
  position: relative;
}
.jstree-default .jstree-anchor {
  width: calc(100% - 19px);
}
.jstree-rename-input {
  max-width: calc(100% - 24px);
}
a.jstree-hovered .btnEditName {
  animation: editName-in 0.3s;
  animation-delay: 0.3s;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
  transition-delay: 0.3s;
  visibility: visible;
}
.addMember-show,
.jobTree-show {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.addMember-hide,
.jobTree-hide {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -ms-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
#addMemberButtonContainer {
  flex: 1;
  padding: 5px;
}
#addMemberButtonContainer .panel .panel-body .btn-sm {
  width: 130px;
}
#addMemberButtonContainer .addMemberImage {
  border: 1px solid;
  border-radius: 0;
  padding: 0;
}
#jobTreeButtons a,
#jobTreeButtons button {
  box-sizing: border-box;
}
.angular-ui-tree-node .treeRoot {
  margin-top: 5px;
  margin-bottom: 5px;
}
.angular-ui-tree-nodes .nameAndEditButton {
  color: #eee !important;
  background: #777;
  user-select: none;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 1.5;
  padding-left: 20px !important;
  margin-left: 0 !important;
  font-weight: normal;
}
.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding: 10px 20px;
}
.angular-ui-tree-nodes .angular-ui-tree-nodes .nameAndEditButton {
  letter-spacing: 1px;
  padding-left: 10px !important;
  margin-left: 10px !important;
  text-transform: none;
  background-color: transparent;
}
.angular-ui-tree-nodes .angular-ui-tree-nodes .nameAndEditButton i {
  display: inline-block;
}
.angular-ui-tree-nodes .angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-top: 1px;
  padding-left: 10px;
}
.angular-ui-tree-handle {
  cursor: auto;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
tr.angular-ui-tree-empty {
  height: 100px;
}
.group-title {
  background-color: #687074 !important;
  color: #FFF !important;
}
/* --- Tree --- */
.tree-node {
  border: 1px solid #dae2ea;
  background: #f8faff;
  color: #7c9eb2;
}
.nodrop {
  background-color: #f2dede;
}
.tree-node-content {
  margin: 10px;
}
.tree-handle {
  padding: 10px;
  background: #428bca;
  color: #FFF;
  margin-right: 10px;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.memberManagementTree .treeItems {
  overflow-y: scroll;
}
.memberManagementTree .treeRoot {
  margin: 0px;
  padding: 0px;
  height: 33px;
}
.memberManagementTree .treeRoot .nameContainer .nameAndEditButton {
  margin-left: 0px;
  width: 100%;
  font-size: 14px !important;
}
.memberManagementTree .level {
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
  height: 33px;
}
.memberManagementTree .level .nameContainer .renameItemForm .renameItemText {
  width: calc(100% - 52px);
}
.memberManagementTree .level .nameContainer .renameItemForm .levelWithoutMember {
  width: calc(100% - 40px);
}
.memberManagementTree .member {
  margin: 0px;
  margin-left: 5px;
  padding: 0px;
  height: 33px;
}
.memberManagementTree .collapseButton {
  float: left;
  margin-right: 5px;
  margin-top: 6px;
  color: #e0e0e0;
  font-size: 12px !important;
}
.memberManagementTree .nameContainer {
  margin: 0px;
  padding: 0px;
}
.memberManagementTree .nameContainer .nameAndEditButton {
  margin: 0px;
  padding: 7px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.memberManagementTree .nameContainer .nameAndEditButton:hover .btnEditName {
  animation: editName-in 0.3s;
  animation-delay: 0.3s;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
  transition-delay: 0.3s;
  visibility: visible;
}
.memberManagementTree .nameContainer .nameAndEditButton .btnEditName {
  top: 7px;
  right: 10px;
}
.memberManagementTree .nameContainer .renameItemForm .renameItemButton {
  height: 33px;
  right: 12px;
}
.memberManagementTree .nameContainer .renameItemForm .renameItemText {
  width: calc(100% - 40px);
  height: 33px;
}
.memberManagementTree ol li div {
  color: #333;
}
.memberManagementTree .treeRoot.selected .nameContainer .nameAndEditButton,
.memberManagementTree .level.selected .nameContainer .nameAndEditButton,
.memberManagementTree .member.selected .nameContainer .nameAndEditButton {
  background: #777;
  color: #eee !important;
}
.treeItemIcon {
  display: inline-block;
  font-size: 17pt;
  width: 21px;
  line-height: 24px;
  background-color: transparent;
  background-position: center center;
  background-size: auto;
  vertical-align: top;
  margin-right: 4px;
  letter-spacing: 0px;
}
.foldercolor {
  color: #825b39;
}
.treeLink {
  position: absolute;
  top: 0px;
  left: 16px;
  font-size: 17px;
  transform: rotate(90deg);
  color: black;
}
.changeToBlack {
  color: black;
}
.changeToOrange {
  color: #FF00FF;
}
.managmentbtngroup {
  width: 100%;
  padding: 20px;
}
.managmentbtngroup .btn-default {
  background-color: #777;
  border-color: rgba(144, 164, 174, 0.6);
}
/* Modal Header */
.addmemebermodal-header {
  padding: 2px 16px;
  font-size: 18px;
  font-weight: normal;
}
.addmemberlabel {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal;
}
/* Modal Body */
.addmembermodal-body {
  padding: 2px 16px;
}
/* Modal Footer */
.addmembermodal-footer {
  padding: 2px 16px;
  color: white;
}
/* Modal Content */
.addmembermodal-content {
  position: absolute;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  margin-top: 15%;
  left: 5vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
}
@media (min-width: 768px) and (orientation: landscape) {
  .addmembermodal-content {
    margin-top: 5vw;
  }
}
/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
::selection {
  color: white;
  background: #71bdff;
}
