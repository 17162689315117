.jobSummaryContainer {
  min-height: 100%;
  height: 100%;
  width: 100%;
}
.jobSummaryContainer .printAllReportGraphic {
  position: absolute;
  width: 800px;
  margin: 0 auto;
  float: inherit;
  padding: 0;
  z-index: -1;
  top: -60px;
}
.jobSummaryContainer .containerName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
}
.jobSummaryContainer .btnMargin {
  margin-bottom: 4px;
  width: 100%;
}
.jobSummaryContainer .buttonsContainer {
  max-width: 250px;
}
.jobSummaryContainer .summaryTableContainer .disabledStyle {
  color: #aaa;
  font-style: italic;
}
.jobSummaryContainer .summaryTableContainer .utilizationPercent {
  display: inline;
  color: black;
}
.jobSummaryContainer .summaryTableContainer .designPassed {
  color: green;
}
.jobSummaryContainer .summaryTableContainer .designFailed {
  color: red;
}
.jobSummaryContainer .summaryTableContainer .printContainerCheckbox {
  font-size: 18px;
}
.jobSummaryContainer .summaryTableContainer .printMemberCheckbox {
  margin-left: 10px;
  font-size: 18px;
}
.jobSummaryContainer .summaryTableContainer .labelmember {
  white-space: nowrap;
}
.jobSummaryContainer .summaryTableContainer .panel-body {
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.jobSummaryContainer .summaryTableContainer .panel-body:hover {
  background-color: #dfdfdf !important;
}
.jobSummaryContainer .summaryTableContainer .panel-group {
  padding-top: 0px;
}
.jobSummaryContainer .summaryTableContainer .lines {
  padding-bottom: 8px;
}
.jobSummaryContainer .lines {
  padding-bottom: 8px;
}
#jsReportDocument {
  z-index: -100;
}
#jsReportDocument .ng-hide-add {
  /* ensure visibility during the transition */
  display: none !important;
  /* yes, important */
}
#jsReportDocument table:nth-of-type(1) {
  page-break-inside: auto;
}
#jsReportDocument .reportFooter {
  height: 100%;
  page-break-inside: avoid;
}
#jsReportDocument .reportFooter .reportFooterHeight {
  height: 100%;
}
.jsMemberReport {
  page-break-before: always;
  break-before: page;
}
.jobSummaryReportHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}
.jobSummaryReportTitle {
  color: #696969 !important;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  #jsReportDocument table > tbody > tr > th {
    border: 1px solid grey !important;
    background-color: lightgrey !important;
  }
  .jobSummaryTable tbody > tr > td {
    border: 1px solid grey !important;
  }
  .reportFooter table > thead > tr > th {
    border: 1px solid grey !important;
  }
  .reportFooter table > tbody > tr > td {
    border: 1px solid grey !important;
  }
  .ng-hide {
    display: none !important;
  }
}
