/* inject:lessImports */
/* endinject */
/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
.matManContainer {
  height: 100%;
  width: 100%;
  visibility: visible;
}
.matManContainer .buttonOnLeft {
  text-align: left;
}
.matManContainer .postalCodeContainer {
  width: 117px;
  float: right;
}
.matManContainer .postalCodeContainer .postalCodeInput {
  height: 30px;
  width: 87px;
}
.matManContainer .postalCodeContainer .postalCodeButton {
  height: 30px;
  width: 30px;
}
.matManContainer .postalCodeContainer .postalCodeInputForm input.ng-invalid + button {
  background-color: red;
}
.matManContainer .selected {
  background-color: #4285f4;
  color: white;
}
.matManContainer .productFilterLabel {
  height: 45px;
  width: 100%;
  flex-shrink: 0;
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid black;
}
.matManContainer .dropdownlistcenter {
  padding-left: 0px ;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.matManContainer .dropdownlistcenter .bodytoppadding .dropdownlistwidthm {
  width: 190px;
}
.matManContainer .dropdownlistcenter .dropdown .dropdown-menu .selected {
  background-color: #4285f4;
  color: white;
}
.matManContainer .mmNavColumn {
  flex-shrink: 0;
  width: 205px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
}
.matManContainer .mmNavColumn .productGroupListContainer {
  flex: 1;
}
.matManContainer .mmNavColumn .productGroupListContainer ul {
  list-style-type: none;
  list-style: none;
  padding-left: 0px;
  margin: auto;
  font-size: 16px;
}
.matManContainer .mmNavColumn .productGroupListContainer ul li {
  margin-bottom: -2px;
  line-height: 40px;
  padding-left: 10px;
}
.matManContainer .mmNavColumn .productGroupListContainer ul li:hover {
  cursor: pointer;
}
.matManContainer .mmNavColumn .productGroupListContainer ul .selected {
  background-color: #4285f4;
  color: white;
}
.matManContainer .mmContentColumn {
  height: 100%;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.matManContainer .mmContentColumn .navBar {
  height: 48px;
  flex-shrink: 0;
  font-size: 20px;
  display: flex;
  background-color: lightgrey;
}
.matManContainer .mmContentColumn .productListContainer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-min-height: 0;
}
.matManContainer .mmContentColumn .productListContainer .productSeriesList {
  list-style: none;
}
.matManContainer .mmContentColumn .productListContainer .productSeriesList li {
  margin-left: -25px;
  margin-bottom: -2px;
}
.matManContainer .productListContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 0px;
}
.matManContainer .productList {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 0px;
}
.matManContainer .productSeriesTable {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
}
.matManContainer .productCheckbox {
  margin-top: 1px;
  font-size: 18px;
}
.matManContainer .productSeriesCheckbox {
  margin-top: 2px;
  margin-left: 8px;
  font-size: 18px;
}
.matManContainer .productSeriesCheckbox_checked {
  margin-left: 1px;
}
.matManContainer .productSeriesHeader {
  margin-left: 3px;
  cursor: pointer;
}
.matManContainer .productRow {
  margin-left: 10px;
}
.matManContainer .productSeriesRowCaret {
  margin-top: 11px;
  font-size: 18px;
}
.matManContainer .productLabel {
  margin-left: 5px;
  vertical-align: top;
}
.matManContainer .productLabel_checked {
  margin-left: 3px;
}
.matManContainer .nonBoldLabel {
  font-weight: normal;
}
.matManContainer input[type='checkbox'] {
  width: 18px;
  margin-right: 5px;
  float: left;
  height: 18px;
}
.matManContainer #btnBackFileManagement {
  width: 200px;
  height: 55px;
  bottom: 23px;
  right: 36px;
  cursor: pointer;
  position: absolute;
}
.matManContainer #btnBackFileManagement .fab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #F44336;
  color: white;
  display: block;
  position: absolute;
  right: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 20px;
  box-shadow: 0px 2px 5px #666;
  text-shadow: 1px 1px #720000;
}
.matManContainer #btnBackFileManagement .backButtonIcon {
  line-height: 40px;
}
.matManContainer #btnBackFileManagement .fabFlyoutText {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-shadow: 1px 1px #000000;
  border-radius: 3px;
  display: inline;
  position: absolute;
  right: 20px;
  padding: 2px 10px;
  top: 15px;
  opacity: 0;
  box-shadow: 0px 2px 5px #666;
  transition: all 0.3s;
}
.matManContainer #btnBackFileManagement:hover .fabFlyoutText {
  right: 60px;
  opacity: 1;
}
@keyframes fade-in {
  from {
    opacity: 0;
    right: 20px;
  }
  to {
    opacity: 1;
    right: 70px;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    right: 70px;
  }
  to {
    opacity: 0;
    right: 20px;
  }
}
@keyframes close-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-180deg);
  }
}
@keyframes close-out {
  from {
    opacity: 0;
    transform: rotateZ(-180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes back-in {
  from {
    opacity: 0;
    transform: rotateZ(70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes back-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(70deg);
  }
}
.subjectheader {
  background-color: #555;
  color: #fff;
  padding: 8px;
  margin: 0px;
}
.removepaddingLeftRight {
  padding-right: 0px;
  padding-left: 0px;
}
.selectioncheckbox {
  margin-left: 10px !important;
  margin-top: 8px !important;
}
.checkboxtextalign {
  display: block;
  padding-left: 25px;
  text-indent: 0px;
}
.panel-group-matrialmanager {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.panel-group .specialOrder,
panel-collapse .specialOrder {
  background-color: #ffdb99 !important;
}
.panel-group .unavailable,
panel-collapse .unavailable {
  background-color: #ff9999 !important;
}
.panel-group .mixedAvailability,
panel-collapse .mixedAvailability {
  background-color: #b8b8b8 !important;
}
.panel-body-matriamanager {
  padding: 0px !important;
}
.panel-body-matriamanager .removePaddingRight {
  padding-right: 0px;
}
.removemargin {
  margin-bottom: -5px !important;
}
