/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/*LOCATION ANALYSIS*/
/* inject:lessImports */
/* endinject */
.locationsContainer {
  float: left;
  position: relative;
  width: 100%;
}
.locationsContainer .panel-group {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.addLocationButton {
  width: 190px;
}
.addLocationButton:focus {
  outline: 0;
}
.addLocationButton:hover {
  cursor: pointer;
}
.addLocationButton div {
  display: inline-flex;
  width: auto;
  position: relative;
  top: -1px;
}
.addLocationButton div div {
  top: -2px;
}
.addLocationDiv {
  vertical-align: middle;
  text-align: center;
  padding-top: 2px;
  font-weight: normal;
}
.iconAddLocation {
  font-size: 14px;
  padding-right: 11px;
}
.locationsContainer .form-control {
  border-width: 1;
  text-align: center;
}
.locationsContainer .from-control:focus {
  outline: 0;
  box-shadow: none;
}
.labelLocation {
  float: left !important;
  white-space: nowrap;
}
.spinnerOverlay {
  position: relative !important;
}
.spinner {
  position: relative !important;
}
.deleteLocationAnalysisHole {
  font-size: 14px;
}
.notchedFlangeContainer {
  float: left;
  top: 20px;
  position: relative;
  display: flex;
  margin-bottom: 15px;
  height: 220px;
}
.notchedFlangeContainer .rowHeaderContainer {
  font-weight: bold;
  width: 190px;
  text-align: left;
}
.notchedFlangeContainer .rowHeaderContainer .notchHeader:first-child {
  border-top: 1px solid #ccc;
}
.notchedFlangeContainer .rowHeaderContainer .notchHeader {
  height: 30px;
  padding: 3px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.notchedFlangeContainer .notchColumnHeader {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 250px;
  height: 30px;
  font-weight: bold;
  text-align: center;
  padding-top: 3px;
}
.notchedFlangeContainer .notchColumnHeader .columnDeleteButton {
  left: 197px;
  font-size: 16px;
}
.notchedFlangeContainer .disableOverlay,
.notchedFlangeContainer .spinnerOverlay {
  height: 180px;
  width: 250px;
  z-index: 100;
  left: 190px;
}
.notchedFlangeContainer .notchColumn {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 250px;
  height: 30px;
}
.notchedFlangeContainer .notchColumn div {
  height: 29px;
  text-align: center;
  padding: 4px;
}
.notchedFlangeContainer .notchColumn select {
  height: 29px;
  border-radius: 0%;
  border: none;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  padding: 4px;
}
.notchedFlangeContainer .notchColumn select:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.notchedFlangeContainer .notchColumn input {
  height: 29px;
  border-radius: 0%;
  border: none;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  text-overflow: ellipsis;
}
.notchedFlangeContainer .notchColumn input:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.notchedFlangeContainer .alongTopContainer img {
  position: relative;
  height: 200px;
  left: 180px;
  top: -20px;
}
.notchedFlangeContainer .alongTopContainer span {
  position: relative;
  z-index: 10;
}
.notchedFlangeContainer .alongTopContainer .span1 {
  top: 138px;
  left: 175px;
}
.notchedFlangeContainer .alongTopContainer .span2 {
  top: 50px;
  left: 115px;
}
.notchedFlangeContainer .alongTopContainer .span3 {
  left: 240px;
  top: 6px;
}
.notchedFlangeContainer .alongTopContainer .span3Bottom {
  top: 173px;
  left: 240px;
}
.notchedFlangeContainer .acrossTopContainer img {
  position: relative;
  height: 200px;
  left: 180px;
  top: -20px;
}
.notchedFlangeContainer .acrossTopContainer span {
  position: relative;
  z-index: 10;
}
.notchedFlangeContainer .acrossTopContainer .span1 {
  top: 135px;
  left: 175px;
}
.notchedFlangeContainer .acrossTopContainer .span2 {
  top: 50px;
  left: 115px;
}
.notchedFlangeContainer .acrossTopContainer .span3 {
  top: 23px;
  left: 250px;
}
.notchedFlangeContainer .acrossTopContainer .span3Bottom {
  top: 154px;
  left: 250px;
}
.notchedFlangeContainer .sectionContainer span {
  position: relative;
  z-index: 10;
  font-weight: bold;
}
.notchedFlangeContainer .sectionContainer div {
  position: relative;
}
.notchedFlangeContainer .sectionContainer div .topSection {
  top: -44px;
  left: 10vw;
}
.notchedFlangeContainer .sectionContainer .section {
  top: -35px;
  left: 360px;
}
.notchContainer .panel-default {
  border: 0px;
}
.notchContainer .notchPanel {
  margin-top: 10px;
}
.notchContainer .notchPanel .panel-group {
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 0px;
}
.notchContainer .notchBody .gridColumn .notchGraphContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notchContainer .notchBody .gridColumn .alongTopContainer {
  margin-left: 5vw;
}
.notchContainer .notchBody .gridColumn .alongTopContainer img {
  position: relative;
  height: 200px;
  top: -10px;
}
.notchContainer .notchBody .gridColumn .alongTopContainer span {
  position: relative;
  z-index: 10;
}
.notchContainer .notchBody .gridColumn .alongTopContainer .span1 {
  top: 150px;
  left: -20px;
}
.notchContainer .notchBody .gridColumn .alongTopContainer .span2 {
  top: 50px;
  left: -80px;
}
.notchContainer .notchBody .gridColumn .alongTopContainer .span3 {
  left: 50px;
  top: 6px;
}
.notchContainer .notchBody .gridColumn .alongTopContainer .span3Bottom {
  top: 173px;
  left: 50px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer img {
  position: relative;
  height: 200px;
  top: -15px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer span {
  position: relative;
  z-index: 10;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span1 {
  top: 135px;
  left: -14px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span1Fr {
  top: 155px;
  left: -40px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span2 {
  top: 50px;
  left: -80px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span2Fr {
  top: 49px;
  left: -7px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span3 {
  top: 23px;
  left: 55px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span3Fr {
  top: 40px;
  left: 106px;
}
.notchContainer .notchBody .gridColumn .acrossTopContainer .span3Bottom {
  top: 154px;
  left: 55px;
}
.notchContainer .notchBody .gridColumn .sectionContainer span {
  position: relative;
  z-index: 10;
  font-weight: bold;
}
.notchContainer .notchBody .gridColumn .sectionContainer div {
  position: relative;
}
.notchContainer .notchBody .gridColumn .sectionContainer div .topSection {
  left: -205px;
  top: 90px;
}
.notchContainer .notchBody .gridColumn .sectionContainer .section {
  top: 90px;
  left: -71px;
}
.locationTitles {
  padding-right: 0px;
}
@media (max-width: 325px) {
  .locationTitles label {
    font-size: 12px;
  }
}
