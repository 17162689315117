@media only screen and (min-width: 320px) and (max-height: 320px) and (orientation: landscape) {
  .memberManagementTree .treeItems {
    height: 120px;
  }
}
@media only screen and (min-width: 320px) and (max-height: 480px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 260px;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (min-height: 481px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 350px;
  }
}
@media only screen and (min-width: 360px) and (max-height: 375px) and (min-height: 321px) and (orientation: landscape) {
  .memberManagementTree .treeItems {
    height: 165px;
  }
}
@media only screen and (min-width: 360px) and (max-height: 667px) and (min-height: 569px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 440px;
  }
}
@media only screen and (min-width: 411px) and (max-height: 414px) and (min-height: 376px) and (orientation: landscape) {
  .memberManagementTree .treeItems {
    height: 215px;
  }
}
@media only screen and (min-width: 411px) and (max-height: 736px) and (min-height: 668px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 530px;
  }
}
@media only screen and (min-width: 812px) and (orientation: landscape) {
  .memberManagementTree .treeItems {
    height: 195px;
  }
}
@media only screen and (min-width: 375px) and (min-height: 812px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 590px;
  }
}
@media only screen and (min-width: 600px) and (orientation: portrait) {
  .memberManagementTree .treeItems {
    height: 790px;
  }
}
