/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.memberContainer {
  max-width: 320px;
}
.memberContainer .form-group {
  margin-bottom: 0px !important;
}
.memberContainer .form-group.systemMember {
  padding: 0px !important;
}
.memberContainer div {
  padding-bottom: 4px;
}
.memberContainer div select {
  margin-bottom: 7px !important;
}
.memberContainer .memberInfoLabel {
  white-space: nowrap;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 34px;
  padding-top: 10px;
}
.memberContainer .memberLevelNameContainer {
  margin-bottom: 0px;
}
.memberContainer .memberLevelNameContainer label {
  padding-top: 10px;
}
.memberContainer .memberLevelNameContainer input,
.memberContainer .memberLevelNameContainer select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.memberContainer .memberNotesContainer textarea {
  resize: none;
}
.memberContainer .membePitchInput {
  float: left;
  width: 60px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.memberContainer .membePitchOver12 {
  margin-top: 8px;
  margin-left: 0px;
}
.memberContainer .membePitchInputMessage {
  color: red;
}
.memberContainer .multiPlyContainer {
  width: 286px;
}
.memberContainer .multiPlySelect {
  width: 285px;
}
.memberContainer .deflectionContainer fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 1.4em 0 1.4em !important;
  margin: 0 0 0.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
.memberContainer .deflectionContainer fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.memberContainer .deflectionContainer fieldset div .selectWidth {
  max-width: 250px;
  display: block;
  margin: 0 auto;
}
.memberContainer .deflectionContainer .deflectionInputsContainer div {
  padding-bottom: 0px;
}
.memberContainer .deflectionContainer .deflectionInputsContainer div p {
  font-style: normal;
}
.memberContainer .deflectionContainer .deflectionInputsContainer div input {
  width: 55px;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.memberContainer .deflectionContainer .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
.memberContainer .wallHeaderNotesContainer {
  font-size: 13px;
  display: block;
}
.memberContainer .wallHeaderNotesContainer div ul {
  padding-left: 15px;
}
.memberContainer .wallHeaderNotesContainer label {
  margin-top: 5px !important;
  font-weight: bold;
}
.memberContainer .checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
}
.memberContainer .checkbox label input[type="checkbox"] {
  width: 18px;
  margin: 0px;
  margin-left: -25px;
  margin-right: 5px;
  height: 18px;
}
.memberContainer .memberSettings fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
.memberContainer .memberSettings fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.memberContainer .loadBearingInput {
  width: 80px;
  margin-bottom: 10px;
}
.memberInfoTopMargin {
  margin-top: 10px;
}
.memberInfoTopMargin .memberImage {
  border: 1px solid;
  border-radius: 0;
  padding: 0;
}
