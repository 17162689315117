/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.radioBtnContainer {
  margin-top: 15px;
}
.radioBtnContainer .secondRadioBtn {
  padding-left: 50px;
}
.taperedGridContainer {
  padding-top: 10px;
  float: left;
  position: relative;
  display: flex;
}
.taperedGridContainer .rowHeaderContainer {
  font-weight: bold;
  border: 1px solid #ccc;
  width: 130px;
  float: left;
  display: block;
}
.taperedGridContainer .rowHeaderContainer div {
  height: 30px;
  padding: 3px;
}
.taperedGridContainer .rowHeaderContainer div:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.taperedGridContainer .gridContainer {
  width: auto;
  display: flex;
  width: 700px;
  white-space: nowrap;
}
.taperedGridContainer .gridContainer .leftEndGridOverlay .disableOverlay,
.taperedGridContainer .gridContainer .leftEndGridOverlay .spinnerOverlay {
  height: 120px;
  width: 180px;
  z-index: 100;
  left: 130px;
  top: 10px;
}
.taperedGridContainer .gridContainer .rightEndGridOverlay .disableOverlay,
.taperedGridContainer .gridContainer .rightEndGridOverlay .spinnerOverlay {
  height: 120px;
  width: 180px;
  z-index: 100;
  left: 310px;
  top: 10px;
}
.taperedGridContainer .gridContainer .gridColumn {
  flex-wrap: nowrap;
}
.taperedGridContainer .gridContainer .gridColumn .columnHeaderCell {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 31px;
  font-weight: bold;
  text-align: center;
  padding: 3px;
  position: relative;
}
.taperedGridContainer .gridContainer .gridColumn .columnHeaderCell .columnDeleteButton {
  color: red;
  position: absolute;
  left: 7px;
  top: 5px;
}
.taperedGridContainer .gridContainer .gridColumn .columnHeaderCell .iconSize {
  font-size: 14px;
}
.taperedGridContainer .gridContainer .gridColumn .columnCell {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 30px;
  text-align: center;
}
.taperedGridContainer .gridContainer .gridColumn .columnCell > input[type="text"] {
  border: none;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
  height: 100%;
  width: 100%;
  background-color: transparent;
  text-overflow: ellipsis;
}
.taperedGridContainer .gridContainer .gridColumn .columnCell > input[type="text"]:focus {
  outline-style: none;
  border: 2px solid #4d90fe;
}
.imgContainer {
  margin-top: 5px;
}
.imgContainer .img1Container {
  padding-right: 10vw;
}
.imgContainer .img1Container .span1 {
  position: relative;
  left: 105px;
  z-index: 20;
  display: inline-block;
}
.imgContainer .img1Container .span2 {
  position: relative;
  top: 40px;
  left: 8px;
  z-index: 20;
  display: inline-block;
  width: 50px;
}
.imgContainer .img1Container img {
  top: 20px;
  position: relative;
  width: 170px;
}
.imgContainer .img2Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15vw;
}
.imgContainer .img2Container .span1 {
  position: relative;
  z-index: 20;
  display: inline-block;
  left: 136px;
  width: 40px;
  top: -47px;
}
.imgContainer .img2Container .span2 {
  position: relative;
  top: 34px;
  display: inline-block;
  width: 50px;
  left: 11px;
  z-index: 20;
}
.imgContainer .img2Container img {
  left: 7px;
  position: relative;
  width: 170px;
  top: 9px;
}
.messagesContainer {
  float: left;
  padding-top: 10px;
}
