@media (orientation: landscape) {
  .jobsidenav {
    padding-top: 30px;
  }
}
@media (max-width: 321px) and (min-height: 560px) and (orientation: portrait) {
  .rotateGraph {
    top: 18vh;
    left: -37%;
  }
}
@media (max-width: 321px) and (max-height: 480px) and (orientation: portrait) {
  .rotateGraph {
    top: 18vh;
    left: -26%;
  }
}
@media (min-width: 362px) and (max-width: 376px) and (orientation: portrait) {
  .rotateGraph {
    top: 22.5vh !important;
    left: -46% !important;
  }
}
@media (min-width: 377px) and (max-width: 425px) and (orientation: portrait) {
  .rotateGraph {
    top: 24vh;
    left: -43%;
  }
}
