.levelContainer input {
  text-overflow: ellipsis;
  overflow: hidden;
}
.levelContainer img {
  max-width: 100%;
}
.levelContainer fieldset {
  margin-top: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 0.7em 0.7em 0.7em !important;
  margin: 1em 0 0.7em 0 !important;
  box-shadow: 0px 0px 0px 0px #000;
}
.levelContainer fieldset div {
  margin-bottom: 10px;
}
.levelContainer fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
  margin-bottom: 2px;
}
.levelContainer fieldset .legendSubText {
  margin-bottom: 10px;
  padding-left: 10px;
}
#buildingCls {
  display: block;
}
.deflectionCriteriaContainer {
  background-color: white;
  width: 200px;
  height: 100px;
}
.deflectionCriteriaContainer li:hover {
  background-color: lightblue;
  border: solid;
  border-color: blue;
}
.ridgeHeightText {
  display: block;
}
.ridgeHeightText input {
  width: 70px;
}
.eaveHeightText {
  display: block;
}
.eaveHeightText input {
  width: 70px;
}
.levelTopMargin {
  margin-top: 10px;
}
