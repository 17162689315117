/* inject:lessImports */
/* endinject */
/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}
.copyMoveDialog {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}
.copyMoveDialog.ng-hide-add {
  animation: 0.35s displayNoneWait ease-in-out;
  display: block !important;
}
.copyMoveDialog.ng-hide-add .splash-right-modal {
  animation: 0.35s fadeOutRight ease-in-out;
}
.copyMoveDialog.ng-hide-add .logged-in-splash.splash-bg.splash-bg-light {
  animation: 0.35s fadeOutCustom ease-in-out;
}
.copyMoveDialog.ng-hide-remove {
  animation: 0.35s displayblockWait ease-in-out;
  display: block !important;
}
.copyMoveDialog.ng-hide-remove .splash-right-modal {
  animation: 0.35s fadeInRight ease-in-out;
}
.copyMoveDialog.ng-hide-remove .logged-in-splash.splash-bg.splash-bg-light {
  animation: 0.35s fadeInCustom ease-in-out;
}
.copyMoveDialog .logged-in-splash.splash-bg.splash-bg-light {
  background-color: #ffffff;
  opacity: 0.7;
}
@keyframes displayNoneWait {
  0% {
    display: block !important;
  }
  99% {
    display: block !important;
  }
  100% {
    display: none !important;
  }
}
@keyframes displayblockWait {
  0% {
    display: none !important;
  }
  1% {
    display: block !important;
  }
  100% {
    display: block !important;
  }
}
@keyframes fadeOutCustom {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInCustom {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0.7;
  }
}
.splash-right-modal {
  display: flex !important;
  flex-direction: column;
  position: fixed;
  top: 75px;
  outline: 1px solid transparent;
  width: 340px;
  overflow: auto;
  background: #ffffff;
  box-shadow: -30px 0 30px -30px rgba(0, 0, 0, 0.9);
  /* padding: 28px 40px 20px; */
  right: 0px !important;
  left: initial;
  height: calc(100% - 75px);
  margin-left: calc(100% - 340px);
}
.splash-right-modal > div {
  padding: 15px 40px 15px 40px;
}
.splash-right-modal .right-modal-header {
  height: 95px;
  flex-shrink: 0;
  font-size: 16px;
  color: #ffffff;
  background-color: #0d3e69;
}
.splash-right-modal .right-modal-header div:first-child {
  padding-bottom: 13px;
  font-size: 20px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul {
  list-style-type: none;
  padding-left: 0px;
  display: inline-flex;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li {
  cursor: default;
  display: inline-flex;
  padding-right: 2px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li.placeholder {
  font-weight: normal;
  color: #cdcdcd;
  font-size: 16px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li.placeholder .clickable {
  cursor: pointer;
  color: #cdcdcd;
  font-size: 16px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li.placeholder .clickable:hover {
  background-color: #008fff;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li + .clickable {
  cursor: pointer;
  color: #cdcdcd;
  font-size: 16px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li .carret {
  font-weight: normal;
  font-size: 16px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li .name {
  font-weight: normal;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li .name:hover {
  background-color: #008fff;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li span {
  max-width: 114px;
  max-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #cdcdcd;
  padding: 0px 3px 0px 3px;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li span .name {
  font-weight: normal;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li span:not(:last-child):hover {
  background-color: #008fff;
  text-decoration: none;
}
.splash-right-modal .right-modal-header .right-modal-breadcrumbs ul li:last-child span {
  color: #ffffff;
  cursor: default;
}
.splash-right-modal .right-modal-header .closeButton {
  float: right;
  font-size: 22px;
  margin-top: -5px;
  cursor: pointer;
}
.splash-right-modal .right-modal-browser {
  flex: 1;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}
.splash-right-modal .right-modal-browser ul {
  list-style-type: none;
  padding: 0;
}
.splash-right-modal .right-modal-browser ul li {
  padding: 10px;
}
.splash-right-modal .right-modal-browser ul li .addNew {
  color: blue;
}
.splash-right-modal .right-modal-browser ul li:hover {
  background: #e9e9e9;
  cursor: pointer;
}
.splash-right-modal .right-modal-browser ul i {
  padding-right: 8px;
}
.splash-right-modal .right-modal-footer {
  height: 65px;
  flex-shrink: 0;
}
