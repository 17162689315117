/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.roofGeometry .roofSlopeContainer input {
  float: left;
  width: 85px;
  margin-right: 9px;
  margin-bottom: 10px;
}
.roofGeometry .roofSlopeContainer label {
  margin-left: 0px;
}
.roofGeometry .roofSlopeContainer .labelData {
  margin-top: 8px;
}
.roofGeometry .roofSlopeContainer div {
  padding-right: 0px;
}
.roofGeometry fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  margin: 0 0 1em 0 !important;
}
.roofGeometry fieldset legend {
  width: auto;
  font-size: 14px;
  font-weight: bold;
}
.roofGeometry fieldset input[type="checkbox"] {
  margin: 0px;
  height: 18px;
}
.roofGeometry .checkbox {
  margin-bottom: 0px;
  margin-top: 0px;
}
.roofGeometry .checkbox label input[type="checkbox"] {
  width: 18px;
  margin: 0px;
  margin-left: -25px;
  margin-right: 5px;
  height: 18px;
}
.roofGeometry .comboBoxWidth {
  min-width: 100px;
}
.roofGeometry .labelMargin {
  margin-top: 15px;
  margin-bottom: 6px;
}
.roofGeometry .warningContainer {
  color: red;
}
.roofGeometry .clearButtonMargin {
  margin-top: 15px;
  margin-bottom: 15px;
}
