/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.jobSettingsContainer {
  max-width: 320px;
}
.jobSettingsContainer .checkVibrationModification {
  display: flex;
}
.jobSettingsContainer .checkVibrationModification label {
  font-weight: normal;
}
.jobSettingsContainer .checkVibrationModification input {
  height: 18px;
  width: 40px;
  margin-right: 10px;
}
.jobSettingsContainer .rowContainer {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
}
.jobSettingsContainer .rowContainer .selectContainer {
  margin-right: 50px;
}
.jobSettingsContainer .rowContainer .selectContainer select {
  width: 260px;
  margin-bottom: 10px;
}
.jobSettingsContainer .rowContainer .jobNotesContainer textarea {
  resize: none;
  width: 300px;
  height: 103px;
}
.jobSettingsContainer .LoadDurationFactorsContainer {
  padding-right: 0px;
  padding-left: 0px;
}
.jobSettingsContainer .LoadDurationFactorsContainer .loadFactor:focus {
  border: 2px solid #71bdff;
}
.jobSettingsContainer .LoadDurationFactorsContainer fieldset {
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
.jobSettingsContainer .LoadDurationFactorsContainer fieldset legend {
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.jobSettingsContainer .LoadDurationFactorsContainer fieldset .loaddurationfactors div {
  position: relative;
}
.jobSettingsContainer .LoadDurationFactorsContainer fieldset .loaddurationfactors div:last-child {
  margin-bottom: 0px;
}
.jobSettingsContainer .LoadDurationFactorsContainer fieldset .loaddurationfactors div select {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.jobSettingsContainer .LoadDurationFactorsContainer .softwareOperatorContainer {
  margin-left: 110px;
  height: 70px;
}
.jobSettingsContainer .LoadDurationFactorsContainer .softwareOperatorContainer input {
  width: 300px;
}
.jobSettingsContainer .saveAsDefaultBtnContainer {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
}
.jobSettingsContainer .saveAsDefaultBtnContainer div {
  vertical-align: middle;
  padding-top: 6px;
  margin-top: 18px;
  margin-left: 20px;
}
.jobsettingsTopMargin {
  margin-top: 10px;
}
