/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.allSupportsPendingContainer {
  display: flex;
  height: 100%;
  position: relative;
}
.allSupportsPendingContainer .spinnerOverlay .spinner {
  right: auto;
  left: 5px;
}
.dimensionLocationOptions {
  font-size: 14px;
  margin-bottom: -1px;
  margin-top: 15px;
}
.graphicPinned {
  font-size: 14px;
  margin-bottom: -1px;
  margin-top: 35px;
}
.dimensionLocationOptions span {
  margin: 15px 6px 0 3px;
  vertical-align: text-bottom;
}
.ssGridColumn div {
  width: 164px;
  padding-top: 6px;
  text-align: center;
}
.spansContainer {
  margin-bottom: 25px;
}
.spansContainer .form-control {
  border-width: 0;
  text-align: center;
  margin-top: -6px;
}
.forteGridContainer .spansColumnContainer .rowHeader {
  width: 90px;
}
.forteGridContainer .spansRowHeaderContainer {
  width: 85px;
}
.forteGridContainer .supportsRowHeaderContainer {
  width: 158px;
}
.forteGridContainer .scrollContainer .spansColumnContainer .ssGridColumn {
  width: 165px;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn {
  width: 165px;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn .supportsCell {
  text-align: left;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn .supportsCell input {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  width: 100%;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn .supportsCell .centerText {
  padding-left: 0;
  text-align: center;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn .supportsCellHeader {
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ccc;
  padding-top: 4px;
}
.forteGridContainer .scrollContainer .supportsGridColumnContainer .gridColumn .supportsCellHeader.deSelectedSupport {
  background-color: transparent;
}
.trashIcon {
  color: red;
}
.supportsDropDown {
  text-align: left;
  padding-left: 2px;
}
.supportsDropDown:disabled {
  cursor: default;
}
.supportConnectorInformationLink {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
/* Make clicks pass-through */
.connectorDialogImageLeft {
  width: 200px;
  height: 200px;
  float: right;
  margin-right: 46px;
  margin-bottom: 76px;
}
.connectorDialog .modal-dialog {
  width: 600px;
}
.connectorDialogSupportDepth {
  height: 140px;
  border-width: 1px;
  border-style: solid;
  border-color: #548DD4;
  padding: 10px;
}
.connectorDialogSupportDepth label input {
  width: 50px !important;
  pointer-events: none;
  background: #ffffff !important;
  border-top-color: #b3b3b3;
}
.connectorDialogSupportDepth label input:disabled {
  background-color: #eee !important;
  color: #747474 !important;
  border-color: #ddd !important;
}
.skewDialogFieldset {
  height: 172px;
  border-style: solid;
  border-width: 1px;
  border-color: #548DD4;
  padding: 10px;
  background-color: #ffffff;
  cursor: not-allowed;
}
.pitchDialogFieldset {
  height: 125px;
  border-style: solid;
  border-width: 1px;
  border-color: #548DD4;
  padding: 10px;
  background-color: #ffffff;
}
.overlay {
  position: absolute;
  visibility: visible;
  z-index: 1;
  font-size: 30px;
  opacity: 0.5;
  font-weight: bold;
  left: 24px;
  bottom: 105px;
  color: red;
  padding-top: 30px;
  cursor: not-allowed;
  /* Safari */
  -webkit-transform: rotate(-35deg);
  /* Firefox */
  -moz-transform: rotate(-35deg);
  /* IE */
  -ms-transform: rotate(-35deg);
  /* Opera */
  -o-transform: rotate(-35deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.overlayOffset {
  position: absolute;
  visibility: visible;
  z-index: 1;
  font-size: 27px;
  opacity: 0.5;
  font-weight: bold;
  bottom: 40px;
  width: inherit;
  color: red;
  right: 3px;
  padding-top: 30px;
  cursor: not-allowed;
  /* Safari */
  -webkit-transform: rotate(-30deg);
  /* Firefox */
  -moz-transform: rotate(-30deg);
  /* IE */
  -ms-transform: rotate(-30deg);
  /* Opera */
  -o-transform: rotate(-30deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}
.offsetDialogFieldset {
  height: 125px;
  border-style: solid;
  border-width: 1px;
  border-color: #548DD4;
  padding: 10px;
  background-color: #ffffff;
  cursor: not-allowed;
}
.concealedFlangeFieldset {
  height: 130px;
  border-style: solid;
  border-width: 1px;
  border-color: #548DD4;
  padding: 10px;
}
.fieldsetLegend {
  font-weight: normal;
  font-size: 13px;
  border-bottom: 0px;
  width: auto;
  margin-bottom: 0px;
}
.matchPlumbDepthCheckBox {
  width: 50px;
  pointer-events: none;
  background: grey;
}
.matchPlumbDepthCheckBox {
  width: 50px;
  background: #eeeeee;
  pointer-events: none;
  border-top-color: #b3b3b3;
}
.selectLabels {
  font-weight: normal;
  font-size: 13px;
  font-weight: bold;
}
#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 50px #29d, 0 0 50px #29d;
  opacity: 1;
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lengthcenter {
  text-align: center;
}
.boxblock {
  min-height: 217px;
  min-width: 220px;
}
.boxblock-bearingWidth {
  min-height: 205px;
  min-width: 220px;
}
.boxblock-bearingWidthDDL {
  min-height: 100px;
  min-width: 182px;
  float: none;
  margin: 0 auto;
  padding: 0px;
}
.boxblock-skewDialogFieldset {
  min-height: 230px;
  min-width: 220px;
}
.boxblock-skewDialogFieldset-marginbottom {
  margin-bottom: 30px;
}
.boxblock-offsetDialogFieldset {
  min-height: 160px;
  min-width: 220px;
}
.boxblock-pitchDialogFieldset {
  min-height: 160px;
  min-width: 220px;
}
.boxblock-concealedFlangeFieldset {
  min-height: 200px;
  min-width: 220px;
}
.supportPlies-dropdownlist {
  width: 80px;
  margin-left: 100px;
  margin-bottom: 20px;
}
.material-switch > input[type="checkbox"] {
  display: none;
}
.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}
.material-switch > label::before {
  background: #000000;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}
.checkboxDiv {
  padding-left: 0px;
  margin-bottom: 15px;
  font-weight: normal;
  padding-right: 0px;
}
.checkboxDiv .removePaddingLeft {
  padding-left: 0px;
  padding-top: 10px;
}
.checkboxDiv .removePaddingRight {
  padding-right: 0px;
}
.checkboxDiv > span {
  padding-left: 0px;
}
.img-bearing {
  width: 200px;
  height: 200px;
}
.img-bearing2 {
  width: 200px;
  height: 200px;
}
.img-skewImage {
  width: 80px;
  height: 80px;
  float: right;
}
.img-offsetImage {
  width: 80px;
  height: 80px;
  margin-top: -30px;
  float: right;
}
.img-concealedFlange {
  width: 80px;
  height: 80px;
  float: right;
}
.modal-title-connector {
  font-size: 15px !important;
  float: left;
}
.modal-title-helpicon {
  float: right;
  padding-top: 4px;
}
.supportPliesSelect {
  margin-bottom: 10px;
}
.marginTop {
  margin-top: 10px;
}
.connectorModal {
  padding-bottom: 0px;
}
.connectorModal .top {
  height: 230px;
}
.connectorModal .bottom {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
}
.connectorModal .bottom label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal;
}
.connectorModal .bottom .pitchDialogFieldset {
  width: 200px;
}
.connectorModal .bottom .concealedFlangeFieldset img {
  margin: 0 auto;
  display: block;
}
.connectorModal .bottom .concealedFlangeFieldset .labels {
  margin: 0 auto;
  width: 40%;
}
.connectorModal fieldset legend {
  font-size: 13px;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 7px;
  border-bottom: none;
}
.connectorModal fieldset label {
  display: block;
}
.connectorModal fieldset label input[type="radio"] {
  float: left;
}
.connectorModal fieldset .floatLeft {
  font-size: 12px;
}
.connectorModal fieldset .labels {
  font-size: 12px;
}
.connectorModal fieldset:not(:first-child) {
  margin-left: 10px;
}
.connectorModal fieldset {
  border-style: solid;
  border-width: 1px;
  border-color: #548DD4;
  padding: 10px;
}
.connectorModal fieldset input[type="radio"] {
  margin-right: 5px;
}
.supportPilesDDL {
  margin-top: 20px;
  margin-bottom: 15px;
  float: left;
}
