/* inject:lessImports */
/* endinject */
#saveAsDefaultButton {
  font-size: 12px !important;
  font-weight: bold !important;
}
.saveAsDefaultButtonG {
  width: 190px;
}
.saveAsDefaultButtonG:hover {
  color: #333333;
  background-color: #ebebeb;
  border-color: #adadad;
  height: -34px !important;
}
.saveAsDefaultBtnContainer {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
}
.saveAsDefaultBtnContainer div {
  vertical-align: middle;
  padding-top: 6px;
  margin-top: 18px;
  margin-left: 20px;
}
.importantButtonStyles {
  height: 34px !important;
  border-width: 1px !important;
  border-radius: 4px !important;
}
.saveTextRight {
  animation-name: save-right;
  animation-duration: 2s;
  animation-timing-function: linear;
  display: inline-block;
  animation-fill-mode: forwards;
}
@keyframes save-right {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  15% {
    opacity: 0;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
.saveTextLeft {
  animation-name: save-left;
  animation-duration: 2s;
  animation-timing-function: linear;
  display: inline-block;
}
@keyframes save-left {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.circleloader {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleloader,
.circleloader:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-spin {
  0% {
    transform: translate3d(190%, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(190%, 0, 0) rotate(360deg);
  }
}
.circleLoaderRight {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-right;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleLoaderRight,
.circleLoaderRight:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-right {
  0% {
    transform: translate3d(-190%, 0, 0) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(190%, 0, 0) rotate(360deg);
    opacity: 1;
  }
}
.circleLoaderLeft {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-left;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleLoaderLeft,
.circleLoaderLeft:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-left {
  0% {
    transform: translate3d(190%, 0, 0) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: translate3d(-190%, 0, 0) rotate(0deg);
    opacity: 0;
  }
}
.loadComplete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
  transform: translate3d(190%, 0, 0);
  width: 25px;
  height: 25px;
}
.circleLoaderHide {
  height: 0px;
  width: 0px;
  border: 0px;
  display: none;
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmarkanimation;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 12.5px;
  width: 6.25px;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: '';
  left: 6.25px;
  top: 12.5px;
  position: absolute;
}
@keyframes checkmarkanimation {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 6.25px;
    opacity: 1;
  }
  40% {
    height: 12.5px;
    width: 6.25px;
    opacity: 1;
  }
  100% {
    height: 12.5px;
    width: 6.25px;
    opacity: 1;
  }
}
.saveTextRightFr {
  animation-name: save-rightfr;
  animation-duration: 2s;
  animation-timing-function: linear;
  display: inline-block;
  animation-fill-mode: forwards;
}
@keyframes save-rightfr {
  0% {
    transform: translate3d(0, -260%, 0);
    opacity: 1;
  }
  15% {
    opacity: 0;
  }
  100% {
    transform: translate3d(100%, -260%, 0);
    opacity: 0;
  }
}
.saveTextLeftFr {
  animation-name: save-leftfr;
  animation-duration: 2s;
  animation-timing-function: linear;
  display: inline-block;
}
@keyframes save-leftfr {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.circleloaderFr {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-spinfr;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleloaderFr,
.circleloaderFr:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-spinfr {
  0% {
    transform: translate3d(-10%, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-10%, 0, 0) rotate(360deg);
  }
}
.circleLoaderRightFr {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-rightfr;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleLoaderRightFr,
.circleLoaderRightFr:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-rightfr {
  0% {
    transform: translate3d(-380%, 0, 0) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(-10%, 0, 0) rotate(360deg);
    opacity: 1;
  }
}
.circleLoaderLeftFr {
  margin: -3px 0 30px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation-name: loader-leftfr;
  animation-duration: 1s;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.circleLoaderLeftFr,
.circleLoaderLeftFr:after {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
@keyframes loader-leftfr {
  0% {
    transform: translate3d(-10%, 0, 0) rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: translate3d(-380%, 0, 0) rotate(0deg);
    opacity: 0;
  }
}
.loadCompleteFr {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
  transform: translate3d(-10%, 0, 0);
  width: 25px;
  height: 25px;
}
.circleLoaderHideFr {
  height: 0px;
  width: 0px;
  border: 0px;
  display: none;
}
.checkmarkFr.drawFr:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmarkanimation;
  transform: scaleX(-1) rotate(135deg);
}
.checkmarkFr:after {
  opacity: 1;
  height: 12.5px;
  width: 6.25px;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: '';
  left: 6.25px;
  top: 12.5px;
  position: absolute;
}
@keyframes checkmarkanimationfr {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 6.25px;
    opacity: 1;
  }
  40% {
    height: 12.5px;
    width: 6.25px;
    opacity: 1;
  }
  100% {
    height: 12.5px;
    width: 6.25px;
    opacity: 1;
  }
}
.hideelement {
  display: none;
}
