@media (min-width: 320px) {
  .jobSummaryContainer .containerName {
    max-width: 55%;
  }
}
@media (min-width: 375px) {
  .jobSummaryContainer .containerName {
    max-width: 55%;
  }
}
@media (min-width: 411px) {
  .jobSummaryContainer .containerName {
    max-width: 65%;
  }
}
@media (min-width: 414px) {
  .jobSummaryContainer .containerName {
    max-width: 67%;
  }
}
@media (min-width: 768px) {
  .jobSummaryContainer .containerName {
    max-width: 82%;
  }
}
@media (min-width: 1024px) {
  .jobSummaryContainer .containerName {
    max-width: 87%;
  }
}
