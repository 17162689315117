/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.manageContainer {
  height: 100%;
}
.manageContainer .btnDefault {
  background-color: #777;
  color: #eee;
}
.manageContainer .btnDefault .customPlus {
  color: green;
}
.manageContainer .btnDefault img {
  padding-right: 5px;
  margin-bottom: 3px;
}
.manageContainer .customPanelGroup {
  padding-left: 9px;
  padding-right: 9px;
}
.fileManagmentLeftSidebar {
  height: 100%;
}
.fileManagementGroups .btnDefault {
  top: 62px;
  z-index: 777;
}
.fileManagementGroups .container {
  width: 100% !important;
  padding-top: 2px;
  padding-bottom: 2px;
}
.fileManagementGroups .container .customFixTeamContent {
  overflow-y: scroll;
  min-width: 200px;
  margin-bottom: 50px;
}
.fileManagementGroups .container .customFixTeamContent .panel-group {
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.fileManagementGroups .fileManagerNavHeader {
  background-color: #cbcaca;
  color: #000;
  font-size: 16px;
  padding: 10px 15px;
  border-bottom: 1px solid #fff;
  word-wrap: break-word;
  text-transform: uppercase;
}
.fileManagementGroups .fileManagerNavHeader .cutText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none !important;
}
.fileManagementGroups .fileManagerNavHeader > h4 {
  font-size: 14px;
}
.fileManagementGroups .fileManagerNavHeader > h4 .glyphicon {
  display: unset;
  margin-right: 4px;
}
.fileManagementGroups .fileManagerNavHeader > h4 .fa {
  display: unset;
  margin-right: 4px;
}
.fileManagementGroups .fileManagerNavHeader.current {
  background-color: #777;
  color: #000;
  border: 0px 1px 1px 1px solid #ddd;
}
.fileManagementGroups .fileManagerNavHeader.current ~ div ul li.currentContent {
  background: #ccc;
  color: #000;
  border: 0px 1px 1px 1px solid #ddd;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
  -moz-border-radius: 0px 0px 4px 4px;
  border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px 0px 4px 4px;
}
.fileManagementGroups .fileManagerNavHeader.current ~ div ul li.currentContent > a {
  color: #000;
}
.fileManagementGroups .fileManagerNavHeader.current > h4 {
  font-weight: bold;
  color: white;
}
.fileManagementGroups .clickableTeamName {
  cursor: pointer;
  user-select: none;
  position: relative;
}
.fileManagementGroups .clickableTeamName:hover {
  opacity: 0.6;
}
.fileManagementGroups .clickableTeamName:hover .btnManageTeam {
  animation: editName-in 0.4s;
  animation-delay: 0s;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s;
  transition-delay: 0s;
  visibility: visible;
}
.fileManagementGroups .clickableTeamName .btnManageTeam {
  position: absolute;
  top: 10px;
  right: 4px;
  cursor: pointer;
  opacity: 0;
  visibility: visible;
  -webkit-transition: visibility 1.5s, opacity 0.2s;
  transition: visibility 1.5s, opacity 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  -webkit-animation: editName-out 0.3s;
  animation: editName-out 0.3s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  color: black;
}
.fileManagementGroups .clickableTeamName .teamNameText {
  max-width: 174px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fileManagementGroups .fileManagerNavContent {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 5px;
  margin-right: 5px;
  height: 80vh;
}
.fileManagementGroups .fileManagerNavContent .noTeamsYet {
  color: #7a7a7a;
  padding: 15px;
  border-top: 1px solid black;
}
.fileManagementGroups .fileManagerNavContent ul {
  list-style-type: none;
  padding-left: 0px;
}
.fileManagementGroups .fileManagerNavContent ul li {
  padding-left: 15px;
  padding-top: 5px;
  height: 32px;
  margin-bottom: 10px;
}
.fileManagementGroups .fileManagerNavContent ul li a {
  vertical-align: middle;
  text-decoration: none;
  color: #000000;
}
.fileManagementGroups .fileManagerNavContent ul li a:hover {
  color: inherit;
}
.fileManagementGroups .fileManagerNavContent ul li:hover {
  background: #f8f8f8;
  cursor: pointer;
}
.fileManagementGroups .fileManagerNavContent ul li .active {
  border-left: 2px solid #016a3a;
  color: #016a3a;
  background: #f4f4f4;
}
.fileManagementGroups .fileManagerNavContent ul li .active:hover {
  background: #eaeaea;
}
.newItemLi {
  height: 36px;
}
.newItemLi .newItemForm {
  height: 36px;
}
.newItemLi .newItemForm .newItemContainer {
  display: inline-flex;
}
.newItemLi .newItemForm .newItemContainer .addNewFolderInput {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}
.newItemLi .newItemForm .newItemContainer input {
  width: 220px;
  height: 36px;
  float: left;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  border: 0;
  padding-right: 10px;
  background: #fff;
  margin-top: -10px;
  margin-left: -10px;
}
.newItemLi .newItemForm .newItemContainer input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemLi .newItemForm .newItemContainer input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemLi .newItemForm .newItemContainer input:-ms-input-placeholder {
  /* IE 10+ */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemLi .newItemForm .newItemContainer input:-moz-placeholder {
  /* Firefox 18- */
  font-style: oblique;
  color: #dbdbdb;
}
.newItemLi .newItemForm .newItemContainer input:focus {
  outline: 0;
}
.newItemLi .newItemForm .newItemContainer .login-validation {
  font: bold 15px 'Tahoma';
  margin: 0;
}
.newItemLi .newItemForm .newItemContainer .addFolderButton {
  font-size: 14px;
  vertical-align: top;
  overflow: visible;
  position: relative;
  float: right;
  border: 1px solid black;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  padding: 0;
  cursor: pointer;
  height: 36px;
  width: 36px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
  margin-left: -9px;
  margin-top: -10px;
}
.newItemLi .newItemForm .newItemContainer .addFolderButton:hover {
  background: #3db8ff;
}
.newItemLi .newItemForm .newItemContainer .addFolderButton:active,
.newItemLi .newItemForm .newItemContainer .addFolderButton:focus {
  background: #0081d1;
  outline: 0;
}
.moveCopyModal {
  flex: 1;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  overflow-x: hidden;
  overflow-y: auto;
}
.moveCopyModal ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.moveCopyModal ul li {
  display: inline-flex;
  padding: 10px 0px 10px 10px;
}
.moveCopyModal ul li .addNew {
  color: blue;
}
.moveCopyModal ul li:hover {
  background: #e9e9e9;
  cursor: pointer;
}
.moveCopyModal ul li span {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.moveCopyModal ul i {
  padding-right: 8px;
}
.fileDeleteModal {
  position: fixed;
  z-index: 10000;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 200px;
  margin-top: -100px;
  margin-left: -150px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
}
.fileDeleteModal .fileDeleteContent {
  padding: 15px;
  flex: 1;
}
.fileDeleteModal .fileDeleteFooter {
  padding: 15px;
  flex-shrink: 0;
  height: calc(35px + (2*15px));
}
.fileDeleteModal .fileDeleteFooter button {
  margin-left: 10px;
  float: right;
}
.fileDeleteModal .fileDeleteHeader {
  font-size: 18px;
  flex-shrink: 0;
  color: #ffffff;
  padding: 15px;
  background-color: #0d3e69;
}
.fileDeleteModal .fileDeleteHeader .closeButton {
  cursor: pointer;
  float: right;
  margin-top: -5px;
}
.fileManagerAddTeam {
  height: 39px;
  margin: auto;
  width: 85%;
  margin-bottom: 10px;
}
.fileManagerAddTeam button {
  width: 100%;
}
.membersidenav {
  width: 0;
  max-width: 360px;
  position: fixed;
  z-index: 1002;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
  background-color: rgba(29, 27, 27, 0.9);
  top: 55px;
}
.membersidenav-header {
  position: absolute;
  top: 0;
  background-color: #000;
  width: 100%;
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}
.membersidenav-title {
  font-size: 20px;
  color: white;
  white-space: nowrap;
}
.membersidenavopenbutton-title {
  font-size: 25px;
  word-wrap: break-word;
  cursor: pointer;
  margin-top: 1px;
  margin-right: 5px;
}
.membersidenav .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 36px;
  color: #f1f1f1;
  padding-top: 3px;
}
@media screen and (max-height: 450px) {
  .membersidenav {
    padding-top: 15px;
  }
  .membersidenav a {
    font-size: 18px;
  }
}
.hrsplitter {
  border-style: dashed;
  size: 2px;
}
.teamleftNavBox {
  border: 1px;
  border-color: #e3e3e3;
  border-style: solid;
  margin-top: 5px;
  -moz-border-radius: 7px 7px 0px 0px;
  border-radius: 7px 7px 0px 0px;
  -webkit-border-radius: 7px 7px 0px 0px;
}
.teamleftNavBox > ul {
  margin-bottom: 0px !important;
}
.teamleftNavBox > ul > li {
  margin-bottom: 0px !important;
}
#accordion .glyphicon {
  margin-right: 10px;
}
.panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-bottom: 0px !important;
}
.panel-collapse > .list-group {
  margin-bottom: 0;
  border: 0px;
}
.panel-collapse .list-group-item {
  border-radius: 0;
}
.panel-collapse .list-group .list-group {
  margin: 0;
  margin-top: 10px;
}
.panel-collapse .list-group-item li.list-group-item {
  margin: 0 -15px;
  border-top: 1px solid #ddd !important;
  border-bottom: 0;
  padding-left: 30px;
}
.panel-collapse .list-group-item li.list-group-item:last-child {
  padding-bottom: 0;
}
.panel-collapse div.list-group div.list-group {
  margin: 0;
}
.panel-collapse div.list-group .list-group a.list-group-item {
  border-top: 1px solid #ddd !important;
  border-bottom: 0;
  padding-left: 30px;
}
.panel-collapse .list-group-item li.list-group-item {
  border-top: 1px solid #DDD !important;
}
.teamsperator {
  background-color: #0081d1;
  height: 2px;
}
.panel-group .panel-default {
  border: 0px;
}
.panel-group .panel-default .panel-heading {
  border: 0px;
}
@media screen and (max-height: 325px) {
  .container .customFixTeamContent {
    height: 48.5vh !important;
  }
}
@media screen and (max-height: 379px) and (min-height: 326px) {
  .container .customFixTeamContent {
    height: 54.5vh !important;
  }
}
@media screen and (max-height: 414px) and (min-height: 380px) {
  .container .customFixTeamContent {
    height: 58vh !important;
  }
}
@media screen and (max-height: 480px) and (min-height: 415px) {
  .container .customFixTeamContent {
    height: 63.5vh !important;
  }
}
@media screen and (max-height: 568px) and (min-height: 481px) {
  .container .customFixTeamContent {
    height: 67.5vh !important;
  }
}
@media screen and (max-height: 640px) and (min-height: 569px) {
  .container .customFixTeamContent {
    height: 70vh !important;
  }
}
@media screen and (max-height: 667px) and (min-height: 641px) {
  .container .customFixTeamContent {
    height: 71vh !important;
  }
}
@media screen and (max-height: 740px) and (min-height: 668px) {
  .container .customFixTeamContent {
    height: 72.5vh !important;
  }
}
@media screen and (max-height: 825px) and (min-height: 741px) {
  .container .customFixTeamContent {
    height: 74.5vh !important;
  }
}
@media screen and (max-height: 1024px) and (min-height: 826px) {
  .container .customFixTeamContent {
    height: 77.5vh !important;
  }
}
