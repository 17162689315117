.teamManagement {
  padding: 0px;
  margin: 0px;
  margin-top: 30px;
}
.teamManagement .renameItemButton {
  font-size: 14px;
  overflow: visible;
  float: right;
  border: 1px solid black;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #fff;
  text-transform: uppercase;
  background: #0099ff;
  margin-top: -1px;
}
.teamManagement .renameItemButton:hover {
  background: #3db8ff;
}
.teamManagement .renameItemButton:active,
.teamManagement .renameItemButton:focus {
  background: #0081d1;
  outline: 0;
}
.teamManagement .renameItemText {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right-width: 0;
  height: 40px;
  font: bold 15px 'lucida sans', 'trebuchet MS', 'Tahoma';
  background: #fff;
  border-radius: 3px 0 0 3px;
  position: relative;
  bottom: 3px;
}
.teamManagement .renameItemText:focus {
  outline: 0;
}
.teamManagement .teamManagementHeader {
  display: inline-flex;
  font-size: 16px;
  width: -webkit-fill-available;
}
.teamManagement .teamManagementHeader h2 {
  margin-bottom: 0px;
}
.teamManagement .teamManagementHeader .btnEditName {
  visibility: visible;
  position: relative;
  opacity: 1;
  float: right;
  margin: 5px;
  top: 0;
  right: 0;
  margin-top: 2px !important;
}
.teamManagement .teamManagementHeader .editButtonIcon {
  line-height: 20px;
  font-size: 14px;
  margin-top: 3px;
}
.teamManagement .teamManagementHeader .btnEditName {
  width: 25px;
  height: 25px;
}
.teamManagement .leaveButton {
  cursor: pointer;
  color: red;
  clear: both;
}
.teamManagement .teamManagementMembers {
  margin-top: 10px;
}
.teamManagement .teamManagementMembers .memberHeader {
  padding-left: 0px;
}
.teamManagement .teamManagementMembers hr {
  border-top: 2px solid #000;
}
.teamManagement .teamManagementMembers .membersTitle {
  float: left;
}
.teamManagement .teamManagementMembers .addButton {
  margin-left: 15px;
  margin-top: 5px;
}
.teamManagement .teamManagementMembers .memberLists {
  padding-right: 0px;
}
.teamManagement .teamManagementMembers .memberLists .titleLabel {
  display: block;
}
.teamManagement .teamManagementMembers .memberLists .borderless td {
  border: none !important;
}
.teamManagement .teamManagementMembers .memberLists .currentUser .memberName:after {
  font-family: FontAwesome;
  content: "\f005";
  padding-left: 5px;
  color: #daa520;
}
.teamManagement .teamManagementMembers .memberLists .memberGroup {
  margin-bottom: 7px;
}
.teamManagement .teamManagementMembers .memberLists .memberGroup button {
  font: inherit;
  /*border is optional*/
  cursor: pointer;
}
.teamManagement .teamManagementMembers .memberLists .memberGroup button:hover {
  text-decoration: underline;
}
.teamManagement .teamManagementMembers .memberLists .memberGroup .currentUser .memberName:after {
  font-family: FontAwesome;
  content: "\f005";
  padding-left: 5px;
  color: #daa520;
}
.teamManagement .removePadding {
  padding: 0px;
}
.teamManagement .inputMax {
  max-width: 500px;
}
.teamManagement .inputTxtBold {
  font-weight: bold;
}
@media screen and (max-width: 554px) {
  .customBtnleft {
    margin-bottom: 6px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 555px) {
  .customBtnleft {
    margin-right: 6px;
    width: 47% ;
    float: left;
  }
  .customBtnRight {
    width: 47%;
  }
}
