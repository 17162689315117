/* inject:lessImports */
/* endinject */
.drop-box {
  background: #F8F8F8;
  border: 3px dashed #DDD;
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 75px;
  cursor: pointer;
  color: #548DD4;
}
.drop-box.dragover {
  border: 5px dashed #548DD4;
}
.drop-box.dragover-err {
  border: 5px dashed red;
}
.resultLog {
  overflow-y: auto;
  padding: 2px;
  background: #F8F8F8;
  min-height: 200px;
  max-height: 486px;
  margin-left: 4px;
  margin-right: 4px;
}
.messageProcess {
  display: table;
  padding: 2px;
  height: 200px;
  margin-left: 4px;
  margin-right: 4px;
  width: 100%;
}
.messageDisplay {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  width: 100%;
}
.headerMessage {
  color: dimgrey;
}
.infoMessage {
  color: dimgrey;
}
.execErrorMessage {
  color: red;
}
.successMessage {
  color: #0000FF;
}
.failedMessage {
  color: red;
}
