/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
#tabcontent {
  width: 90% !important;
  left: 5% !important;
}
.productSelectionContainer img {
  width: 16px;
  height: 16px;
}
.productSelectionContainer select {
  margin-bottom: 5px;
}
.productSelectionContainer input[type="checkbox"] {
  width: 18px;
  margin: 0px;
  margin-right: 5px;
  float: left;
  height: 18px;
}
.productSelectionContainer .productSeriesContainer {
  padding-top: 10px;
}
.productSelectionContainer .allButtonView {
  padding-right: 6px;
}
.productSelectionContainer .selectListContainer {
  position: relative;
  width: 106%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: scroll;
}
.productSelectionContainer .selectListContainer .lablewidth {
  padding: 0;
  font-weight: normal !important;
}
.productSelectionContainer .selectListContainer .checkBoxBorder {
  padding-left: 5px;
}
.productSelectionContainer .selectListContainer .configSelectList {
  width: 100%;
  padding: 4px;
  height: 100%;
}
.productSelectionContainer .selectListContainer .configSelectList option {
  line-height: normal;
}
.productSelectionContainer .selectListContainer .nonResizeSelectList {
  height: 204px;
  width: 100%;
  padding: 4px;
  min-height: 214px;
}
.productSelectionContainer .selectListContainer.productSeries label.specialOrder:before {
  content: "\f071";
  color: #ffa500;
  font-family: fontawesome;
}
.productSelectionContainer .selectListContainer.productSeries label.unavailable:before {
  content: "\f057";
  color: #ff0000;
  font-family: fontawesome;
}
.productSelectionContainer .selectListContainer.productSeries label.mixedAvailable:before {
  content: "\f146";
  color: #6b6b6b;
  font-family: fontawesome;
}
.productSelectionContainer .selectListContainer.productDepths label.specialOrder:before {
  content: "\f071";
  color: #ffa500;
  font-family: fontawesome;
}
.productSelectionContainer .selectListContainer.productDepths label.unavailable:before {
  content: "\f057";
  color: #ff0000;
  font-family: fontawesome;
}
.productSelectionContainer .selectListContainer.solution {
  min-height: 180px;
}
.productSelectionContainer .customDepth {
  margin-top: 5px;
}
.productSelectionContainer .customSpacing {
  margin-top: 5px;
}
.productSelectionContainer .borderlessButtonPs {
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  width: max-content;
}
.wizard {
  margin: 20px auto;
  background: #fff;
}
.wizard .nav-tabs {
  position: relative;
  margin: -28px auto;
  margin-bottom: 0;
  border-bottom-color: #e0e0e0;
}
.wizard > div.wizard-inner {
  position: relative;
}
.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
}
.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}
span.round-tab {
  width: 40px;
  height: 40px;
  line-height: 38px;
  display: inline-block;
  border-radius: 100px;
  background: #fff;
  border: 2px solid #e0e0e0;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 25px;
}
span.round-tab i {
  color: #555555;
}
.wizard li.active span.round-tab {
  background: #337ab7;
  border: 2px solid #2e6da4;
  color: white;
}
.wizard .nav-tabs .item {
  width: 20%;
}
.wizard .nav-tabs .four {
  width: 25% !important;
}
.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: #555555;
  transition: 0.1s ease-in-out;
}
.wizard .nav-tabs > li a {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
}
.wizard .nav-tabs > li a:hover {
  background: transparent;
}
.wizard .tab-pane {
  position: relative;
}
.wizard h3 {
  margin-top: 0;
}
@media (max-width: 585px) {
  .nav-tabs {
    border-bottom: 0px;
  }
  .wizard {
    width: 90%;
    height: auto !important;
  }
  span.round-tab {
    font-size: 16px;
    width: 25px;
    height: 25px;
    line-height: 24px;
  }
  .wizard .nav-tabs > li a {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .wizard li.active:after {
    content: " ";
    position: absolute;
    left: 35%;
  }
}
.carousel-indicators li {
  border: 1px solid #548dd4;
}
.carousel-indicators .active {
  background-color: #4d90fe;
}
@media only screen and (orientation: portrait) {
  .carousel-control.right {
    background-image: none;
    height: 50px;
    color: #4d90fe;
    top: 50%;
    position: fixed;
    float: right;
  }
  .carousel-control.left {
    background-image: none;
    height: 50px;
    color: #4d90fe;
    top: 50%;
    position: fixed;
    float: left;
  }
}
@media only screen and (orientation: landscape) {
  .carousel-control.right {
    background-image: none;
    height: 50px;
    color: #4d90fe;
    top: 75%;
    position: fixed;
    float: right;
    margin-right: -30px;
  }
  .carousel-control.left {
    background-image: none;
    height: 50px;
    color: #4d90fe;
    top: 75%;
    position: fixed;
    float: left;
    margin-left: -30px;
  }
}
.carousel-indicators {
  top: 395px;
  z-index: 0;
}
.productSelectionRedirectBtn {
  margin-top: 20px;
}
input:disabled {
  pointer-events: none !important;
}
.sliderTopMarging {
  margin-top: 10px;
}
.whereToBuyText {
  padding-top: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
