.windLoadingContainer hr {
  margin-bottom: 15px;
  margin-top: 15px;
}
.windLoadingContainer .windLoadSettingsMessage {
  padding-top: 15px;
}
.windLoadingContainer .wlPaddingTop {
  padding-top: 10px;
}
.windLoadingContainer .windSpeedInput input[type="text"] {
  width: 50px;
  float: left;
}
.windLoadingContainer .windSpeedInput label {
  margin: 7px 0px 0px 7px;
}
.windLoadingContainer .windZoneMapAnchor {
  display: block;
  font-size: 60px;
  color: #337AB7;
  width: 100%;
  height: calc(2*15px + 60px);
  padding: 15px;
}
.windLoadingContainer .windZoneMapAnchor .fa {
  margin: 0 auto;
  width: 60px;
  display: block;
  float: left;
}
.windLoadingContainer .windZoneMapAnchor .mapText {
  font-size: 18px;
  line-height: 60px;
  float: left;
  padding-left: 20px;
}
