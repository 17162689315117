/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.userProfileHeader {
  padding-left: 3px !important;
}
.form-group {
  margin-bottom: 10px;
}
.form-group .formgroupPadding {
  margin-bottom: 10px;
}
.form-group .passwordValidationList .titleSizing {
  font-size: 16px !important;
}
.form-group .passwordValidationList ul,
.form-group .passwordValidationList li {
  font-size: 12px !important;
}
.form-group .changePasswordMsg .invalid {
  font-size: 12px !important;
}
.userProfileContainer .changePasswordContainer .changed-successfully {
  margin-top: 5px;
  padding: 5px;
  font-family: Arial;
  font-size: 12px;
  color: #5cb85c;
}
.userProfileContainer .changePasswordContainer .changePasswordMsg {
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  background-color: lightgrey;
  background: #fefefe;
  position: relative;
  z-index: 1;
  display: block;
  padding-left: 15px;
}
.userProfileContainer .changePasswordContainer .changePasswordMsg img {
  position: absolute;
  left: -20px;
  width: 25px;
  top: 4px;
}
.userProfileContainer .changePasswordContainer .changePasswordMsg ul,
.userProfileContainer .changePasswordContainer .changePasswordMsg li {
  font-size: 12px;
  margin: 0;
  padding: 2px 0px 2px 0px;
  list-style-type: none;
}
.userProfileContainer .changePasswordContainer .changePasswordMsg .caseValid {
  background-image: url(/images/valid.png);
  color: #5cb85c;
}
.userProfileContainer .changePasswordContainer h3 {
  margin-top: 10px;
}
.userProfileContainer .changePasswordContainer button {
  width: 100%;
  margin-bottom: 10px;
}
.userProfileContainer .changePasswordContainer input {
  padding-right: 30px;
}
.userProfileContainer .changePasswordContainer i {
  padding-right: 10px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.userProfileContainer .passwordValidationList {
  background-color: lightgrey;
  position: absolute;
  z-index: 1;
  display: block;
  top: -20px;
  left: -2px;
  padding: 15px;
  font-size: 0.875em;
  border-radius: 5px;
  background: #fefefe;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
}
.userProfileContainer .passwordValidationList .caretLefChangeP {
  position: absolute;
  left: -19px;
  width: 25px;
  top: 24px;
}
.userProfileContainer .passwordValidationList h4 {
  margin: 0px;
  font-size: 15px;
}
.userProfileContainer .passwordValidationList ul,
.userProfileContainer .passwordValidationList li {
  font-size: 12px;
  margin: 0;
  padding: 2px 0px 2px 0px;
  list-style-type: none;
}
.userProfileContainer .passwordValidationList .caseValid {
  background-image: url(/images/valid.png);
  color: #5cb85c;
}
hr {
  margin-top: 10px;
  margin-bottom: 0px;
}
.invalidInput:focus {
  border-color: #ec3f41;
  border-size: 2px;
}
.invalidMsg {
  font-size: 12px;
  color: #ec3f41;
}
.msgContainer {
  height: 18px;
}
.profileContainer .eachRow {
  margin-bottom: 0px;
}
.profileContainer #emailDisplay {
  pointer-events: none;
}
.profileContainer #occupationSelect {
  width: 190px;
}
.profileContainer #inputPhone {
  width: 200px;
}
.profileContainer .error-message {
  height: 10px;
  margin: 0px;
}
.profileContainer .marginBottom {
  margin-bottom: 20px;
}
.profileContainer .displayedInfo {
  font-size: 13px;
  margin-top: 20px;
}
.tabWide {
  padding-left: 0px;
  padding-right: 0px;
  padding: 0px !important;
}
.tab-content {
  padding-top: 4vw;
}
.tab-content .tab-pane .form-group .messageLabel {
  font-size: 12px !important;
}
.tab-content .tab-pane .form-group .messageLabelLink {
  color: cornflowerblue;
  text-decoration: underline;
}
.tab-content .tab-pane .form-group .submitButtons {
  float: right;
  margin-bottom: 15px;
}
.tab-content .tab-pane .form-group .submitButtons .btn-danger {
  margin-left: 5px;
}
.tab-pane .changePasswordContainer .changPasswordLable {
  font-size: 16px !important;
}
@media (min-width: 768px) {
  .userProfileContainer .container {
    width: 100% !important;
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
}
