/*COLOR SCHEME!!!!!!!!!!!!!!!!!!!!!!!!
    cyan:   #71FFFF
    red:    #E44C36
    blue:   #1b60ff
*/
/* inject:lessImports */
/* endinject */
.gridLabel {
  font-size: 16px;
}
.messages {
  font-size: 13px;
  padding-top: 5px;
  margin: 0;
}
.headerDimensionRow {
  height: 50px;
  vertical-align: text-top;
}
.reportDocument {
  font-size: 13px;
  line-height: 1.5;
}
.reportDocument .onlyForPrint {
  display: none;
}
.reportDocument .unavailableMessage {
  background-color: #ff0000;
}
.reportDocument .specialOrderMessage {
  background-color: #ffa500;
}
.reportDocument .versionFooter {
  float: right;
  text-align: right;
}
.reportDocument .verticalReportGraphic {
  height: 285px;
}
.reportDocument .verticalReportGraphic img {
  height: 92%;
  display: block;
  margin: 0 auto;
}
.reportDocument .reportFooter {
  height: 120px;
}
.reportDocument ul {
  list-style-type: disc !important;
  padding-left: 15px !important;
  margin-top: -15px;
}
.reportDocument .reportErrorMessages ul {
  margin-top: 0px;
  list-style-type: none !important;
  color: red;
  padding-left: 0px !important;
}
.reportDocument .col-xs-1,
.reportDocument .col-sm-1,
.reportDocument .col-md-1,
.reportDocument .col-lg-1,
.reportDocument .col-xs-2,
.reportDocument .col-sm-2,
.reportDocument .col-md-2,
.reportDocument .col-lg-2,
.reportDocument .col-xs-3,
.reportDocument .col-sm-3,
.reportDocument .col-md-3,
.reportDocument .col-lg-3,
.reportDocument .col-xs-4,
.reportDocument .col-sm-4,
.reportDocument .col-md-4,
.reportDocument .col-lg-4,
.reportDocument .col-xs-5,
.reportDocument .col-sm-5,
.reportDocument .col-md-5,
.reportDocument .col-lg-5,
.reportDocument .col-xs-6,
.reportDocument .col-sm-6,
.reportDocument .col-md-6,
.reportDocument .col-lg-6,
.reportDocument .col-xs-7,
.reportDocument .col-sm-7,
.reportDocument .col-md-7,
.reportDocument .col-lg-7,
.reportDocument .col-xs-8,
.reportDocument .col-sm-8,
.reportDocument .col-md-8,
.reportDocument .col-lg-8,
.reportDocument .col-xs-9,
.reportDocument .col-sm-9,
.reportDocument .col-md-9,
.reportDocument .col-lg-9,
.reportDocument .col-xs-10,
.reportDocument .col-sm-10,
.reportDocument .col-md-10,
.reportDocument .col-lg-10,
.reportDocument .col-xs-11,
.reportDocument .col-sm-11,
.reportDocument .col-md-11,
.reportDocument .col-lg-11,
.reportDocument .col-xs-12,
.reportDocument .col-sm-12,
.reportDocument .col-md-12,
.reportDocument .col-lg-12 {
  padding-left: 0px;
}
.reportDocument .noBorders tr > td {
  border-top-width: 0px;
}
.reportDocument .row {
  margin-right: 0px;
  margin-left: 0px;
}
.reportDocument .sfiLogo {
  width: 215px;
  height: 43px;
  float: right;
}
.reportDocument .reportHeader {
  width: 100%;
  justify-content: center;
}
.reportDocument .reportHeader .reportHeaderLogoContainer {
  height: calc(2*(13px*1.5));
  padding-top: 4px;
}
.reportDocument .reportHeader .reportMemberReportText {
  font-weight: bold;
  color: #8c8c8c !important;
}
.reportDocument .reportHeader .reportProductName {
  font-weight: bolder;
  font-size: 16px;
}
.reportDocument .reportHeader .reportHeaderPassedFailed {
  font-size: 12px;
  font-weight: bolder;
  color: #009d00 !important;
  padding-right: 4px;
  padding-top: 4px;
}
.reportDocument .reportHeader .reportHeaderFailedColor {
  color: #ff0000 !important;
}
.reportDocument .reportHeader .centerText {
  text-align: center;
}
.reportDocument .reportRepeatFooter {
  display: none;
}
.reportDocument .mpceErrors {
  color: #ff0000;
}
.mpceNormalGridStyle {
  color: #333;
}
.mpceNoSolutionGridStyle {
  color: red;
}
.reportSelectElement {
  padding: 0px;
  height: 22px;
}
@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(180deg);
  }
}
@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}
@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  to {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
}
#reportDocument .row table tr .highlightColumHeader {
  background-color: #69a323;
}
#reportDocument .parentheticalHeader {
  margin-top: -15px;
}
#reportDocument .parentheticalHeader label {
  font-weight: normal;
  font-style: italic;
}
.row .structureSystem {
  border-top: solid 1px #ccc;
}
.row .reportsubjectheader {
  background-color: #555;
  color: #fff;
  padding: 8px;
  margin: 0px;
}
.row .panel-group {
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.row .panel-group div .passfailclass {
  white-space: nowrap;
}
.row .panel-group div .passclass {
  color: green;
}
.row .panel-group div .failclass {
  color: red;
}
.row .panel-group .panel-heading {
  border-bottom: #ccc solid 1px;
  border-top: #ccc solid 1px;
  padding: 8px 8px 8px 8px;
}
.row .panel-body {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.row .panel-body div .labelmember {
  color: #000;
  white-space: nowrap;
}
.row .messages {
  margin-top: 10px;
}
.bodytoppadding {
  padding-top: 5px;
}
.panel-body-padding {
  padding-bottom: 5px !important;
}
.bordertop {
  border-top: 1px solid #ccc !important;
}
.setinlineBlock {
  display: inline-flex;
  float: right;
}
.zeroheight {
  height: 0px;
}
.removepadding {
  padding: 0px !important;
}
.printButtonIcon {
  font-size: 14px;
}
.modal-title-custom {
  float: left;
  font-size: 16px;
  font-weight: normal;
}
.helpIcon-right {
  float: right;
}
.connectorInfoManualSelection {
  margin-left: 15px;
  margin-top: 5px;
}
.font13 {
  font-size: 13px;
}
.sectionTopMargin {
  margin-top: 10px;
}
#notchFlange span {
  height: 2px;
}
.bodybottompadding {
  margin-bottom: 10px;
}
